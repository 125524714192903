import axios from 'axios';
import {setPreparedAxiosParams,} from '../services/axios/axiosFunctions';

const prependUserID = (keycloakID) => `/users/${keycloakID}/`;

export const userAPI = {
	getUserInfo: (keycloakID) => axios(prependUserID(keycloakID), {}),
	
	patchUser: (keycloakID, data) =>
		axios.patch(prependUserID(keycloakID), data,),
	
	putUserAvatar: (keycloakID, image) => {
		const data = new FormData();
		data.append('photo', image);
		return axios.put(`/internal-contacts/${keycloakID}/photo/`, data,);
	},
	
	deleteUserAvatar: (keycloakID) =>
		axios.delete(`/internal-contacts/${keycloakID}/photo/`,),
	
	searchUsers() {
		return axios('/users/',
			setPreparedAxiosParams({...arguments[0], ever_have_device: true}),
		)
	},
	
	getAllUsers({page, search, page_size = 25}) {
		return axios('/users/',
			setPreparedAxiosParams({
				...arguments[0],
				ever_have_device: true,
			}),
		)
	},
	
	getAllUsersWithCustomParams({page, search, page_size = 25}) {
		return axios('/users/', setPreparedAxiosParams({...arguments[0]}),
		)
	},
	
	// getAllUsersWithDevice(query) {
	// 	return axios('/users/', {
	// 		...prepareAxiosParams({
	// 			...query,
	// 			have_device_v1_or_v2: true,
	// 			ever_have_device: true,
	// 		}),
	//
	// 	});
	// },
	
	getAllWithoutDevice(
		{
			page,
			search,
			page_size = 25,
			ordering = 'account__last_name',
		}) {
		return axios('/users/',
			setPreparedAxiosParams({
				...arguments[0],
				have_device_v1_or_v2: false,
				ever_have_device: true,
			})
		)
	},
	
	getAllWithCalls(
		{
			page,
			search,
			page_size = 25,
			ordering = 'account__last_name',
		}) {
		return axios('/users/',
			setPreparedAxiosParams({
				...arguments[0],
				have_calls: true,
				ever_have_device: true,
			}),
		)
	},
	
	getAppsAccess: (keycloakID) => axios(`${prependUserID(keycloakID)}apps-settings/`,),
	
	putAppsAccess: (keycloakID, data) =>
		axios.put(`${prependUserID(keycloakID)}apps-settings/`, data,),
	
	getOnUserDivisionAppsAccess: (keycloakID) =>
		axios(`${prependUserID(keycloakID)}division/apps-settings/`,),
	
	// getSitesAccess: (keycloakID) => axios(`${prependUserID(keycloakID)}sites-settings/`,),
	
	// putSitesAccess: (keycloakID, data) =>
	// 	axios.put(`${prependUserID(keycloakID)}sites-settings/`, data,),
	
	// getOnUserDivisionSitesAccess: (keycloakID) =>
	// 	axios(`${prependUserID(keycloakID)}division/sites-settings/`,),
	
	getFeedbacks() {
		return axios('/users/feedbacks/', setPreparedAxiosParams(arguments[0]));
	},
	
	// patchFeedbacks: (id, status) =>
	// 	axios.patch(`/users/feedbacks/${id}/`, status),
	
	getTrackers: () => axios('/trackers/',),
	
	getSubdivisions({page, page_size = 25}) {
		return axios('/users/subdivisions/', setPreparedAxiosParams(arguments[0]));
	},
	
	getLauncherVersions({page, search, page_size = 25}) {
		return axios('/users/launchers/', setPreparedAxiosParams(arguments[0]))
	}
}
