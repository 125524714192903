import { useClickOutside, useToggle, useUpdateEffect } from "@react-hookz/web"
import { useMemo, useRef } from "react"
import { CSSTransition } from "react-transition-group"
import { Card } from "../../../components/UI/Card"
import { ReactComponent as Menu } from './menu.svg';
import { MenuDropdownItem } from "./MenuDropdownItem";
import { navMenuItems} from "../menuItems";
import { loggedAdmin } from "../../../stores/loggedAdmin";
import { useLocation } from "react-router-dom";

export const MenuDropdown = ({className = ''}) => {
  const location = useLocation()
  const {getIsResolved, data} = loggedAdmin
  
  const transitionRef = useRef(null)
  const menuRef = useRef(null)

  const [openMenu, toggleOpenMenu] = useToggle(false)
	useClickOutside(menuRef, () => toggleOpenMenu(false))

  const filteredMembersMenuItems = useMemo(() => 
  navMenuItems.filter(({permission}) => getIsResolved(permission)), [data])

  // const filteredManagementItemsMenu = useMemo(() =>
  // managementMenuItems.filter(({permission}) => getIsResolved(permission)), [data])

  // const filteredTablesMenuItemsMenu = useMemo(() =>
  // tablesMenuItems.filter(({permission}) => getIsResolved(permission)), [data])

  useUpdateEffect(() => {
    toggleOpenMenu(false)
  }, [location])

  return (
    <div  ref={menuRef}
          role={'button'}
          onClick={() => toggleOpenMenu()}
          className={`p-1 cursor-pointer ${className} position-relative`}
    >
      <Menu className={openMenu ? 'svg-primary-color' : ''}/>
      <CSSTransition  nodeRef={transitionRef}
                      in={openMenu}
                      timeout={200}
                      classNames='transition-slide-down'
                      unmountOnExit
      >
        <Card size={2}
						  ref={transitionRef}
						  onClick={e => e.stopPropagation()}
					    className={'cursor-default position-absolute z-c-20 shadow mt-75 position-right-0'}
			  >
          <div style={{maxWidth: '428px'}}>
            {
            filteredMembersMenuItems.length > 0 && 
              <>
                <MenuDropdownItem title={'Администрирование'} items={filteredMembersMenuItems}/>
              </>
              }
              
            {/*{*/}
            {/*  filteredTablesMenuItemsMenu.length > 0 && */}
            {/*  <>*/}
            {/*    <MenuDropdownItem title={'Таблицы данных'} items={filteredTablesMenuItemsMenu}/>*/}
            {/*    <hr/>*/}
            {/*  </>*/}
            {/*}*/}
            {/*{*/}
            {/*  filteredManagementItemsMenu.length > 0 &&*/}
            {/*  <MenuDropdownItem title={'Управление Системой'} items={filteredManagementItemsMenu}/>*/}
            {/*}*/}
          </div>
        </Card>
    </CSSTransition>
    </div>
  )
}