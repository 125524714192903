import {CheckBox} from '../../../../components/form/CheckBox';
import {Head} from '../../../../components/UI/Head';
import {getLocaleFullDate} from '../../../../js-helpers/helpers';
import classNames from 'classnames';

export const DeviceSetupSubStepsList = (
	{subInstruction, onCheck, completedSubSettings, readOnly}
) => {
	return (
		<ul className={'list-unstyled m-2 list-gap-1-5r'}>
			
			{subInstruction.map((instruction, ind) => {
				const {description, subitem, sequence_number, id} = instruction
				
				const date = completedSubSettings
					.find((item) => item.setting.id === id)?.created_at
				
				return (
					<li className={'bg-c-gray-50'} key={id}>
						<CheckBox className={'mr-1 float-left'}
						          white
						          readOnly={readOnly}
						          onChange={e => {
							          onCheck?.(e, instruction, ind)
						          }}
						          checked={!!date}/>
						
						<Head as={'h6'} noMargins className={'mb-50 font-weight-bold'}>
							{`Шаг ${sequence_number}.${subitem}`}
						</Head>
						
						{description}
						
						<p className={classNames(
							{'text-muted': date, 'text-c-transparent': !date},
							'font-small-2 mt-50'
						)
						}>
							{getLocaleFullDate(date)}
						</p>
					</li>
				)
			})
			}
		</ul>
	)
}