import {useEffect, useMemo} from "react"
// import ReactPaginate from "react-paginate"
import {getSetupAutotestsLogs} from "../../../API/api"
import {
	getFullNameFromObj,
	getLocaleFullDate,
	getShortFullNameFromObj,
	isEmpty,
	isNotEmpty,
	loader
} from '../../../js-helpers/helpers';
import {urlParams} from '../../../constants/urlParams';
import {Link} from 'react-router-dom';
import {useComboSearch} from '../../../hooks/url/useComboSearch';
import {useFetchStates} from '../../../hooks/useFetchStates';
import {useUpdateEffect} from '@react-hookz/web';
import {SearchInput} from '../../../components/form/SearchInput';
import {MyTable} from '../../../components/table/MyTable/MyTable';
import {MyTableHead} from '../../../components/table/MyTable/MyTableHead';
import {MyTableBody} from '../../../components/table/MyTable/MyTableBody';
import {useMyTable} from '../../../components/table/MyTable/useMyTable';
import {observer} from 'mobx-react-lite';
import {loggedAdmin} from '../../../stores/loggedAdmin';
import { EmptyFoundDataMessage } from "../../../components/EmptyFoundDataMessage/EmptyFoundDataMessage";
import { EmptyDataWithoutSearch } from "../../../components/EmptyDataWithoutSearch/EmptyDataWithoutSearch";

// <th>ФИО администратора</th>
// <th>Устройство</th>
// <th>Дата и время генерации</th>
// <th>Статус</th>
// <th>Действие</th>

//<td>{getShortFullNameFromObj(item.admin)}</td>
//             <td>{item.device}</td>
//             <td>{new Date(item.date).toLocaleString()}</td>
//             <td>{item.is_success ? 'Успешно' : 'Неуспешно'}</td>
//             <td>
//               <Link to={`/history/${idSetup}/logs/${item.id}`}>Просмотреть</Link>
//             </td>

const getTableColls = (idSetup, isEmptySearchQuery) => [
	{
		head: 'ФИО администратора',
		cell: (admin) => {
			const text = isEmptySearchQuery
				? getShortFullNameFromObj(admin)
				: getFullNameFromObj(admin)
			
			return loggedAdmin.isSuperAdmin
				?
				<Link to={`/admins/${admin.keycloak_id}/`}>
					{text}
				</Link>
				: text
		},
		nestedCellField: 'admin'
	}, {
		head: 'Серийный номер',
		cellClassName: 'py-2',
		cell: 'device',
		sortField: 'device__serial_number'
	}, {
		head: 'Дата и время генерации',
		cell: getLocaleFullDate,
		nestedCellField: 'date',
		sortField: 'date'
	}, {
		head: 'Статус',
		nestedCellField: 'is_success',
		cell: (is_success) => is_success ? 'Успешно' : 'Неуспешно',
		col: {width: 150}
	}, {
		head: 'Действие',
		cell: (id) => <Link to={`/history/${idSetup}/logs/${id}/`}>Просмотреть</Link>,
		nestedCellField: 'id',
		col: {width: 150}
	},
]

const {SEARCH, SORT, PAGE, LIMIT} = urlParams

const searchParams = [
	{param: SEARCH, init: '', debounce: 333},
	{param: SORT, init: '-date'},
	{param: PAGE, init: 1, paramHandler: Number},
	{param: LIMIT},
]

export const Testing = ({idSetup}) => {
	const [
		[urlSearchQuery, setUrlSearchQuery],
		[urlSortField],
		[urlPage, setUrlPage],
		[urlLimit],
	] = useComboSearch(searchParams)
	
	const requestQuery = {
		page: urlPage,
		page_size: urlLimit,
		ordering: urlSortField,
		search: urlSearchQuery,
	}
	
	const logsStates = useFetchStates(
		(_page = urlPage) => getSetupAutotestsLogs(
			{
				...requestQuery,
				page: _page,
				setup: idSetup
			}),
		{noInitFetch: true}
	)
	
	useEffect(() => {
		logsStates.getData()
	}, [urlPage, urlSortField])
	
	useUpdateEffect(() => {
		if (urlPage !== 1) {
			setUrlPage(1)
			return
		}
		logsStates.getData(1)
	}, [urlSearchQuery, urlLimit, urlSearchQuery])
	
	// const {keycloak} = useKeycloak()
	
	// const [data, setData] = useState(null)
	// const [isLoading, setIsLoading] = useState(false)
	
	// const [page, setPage] = useState(1)
	// const [ordering, setOrdering] = useState('-date')
	
	// const [searchInput, setSearchInput] = useState('')
	
	
	// const pageSize = 10
	
	// useEffect(() => {
	//   setIsLoading(true)
	// 	getSetupAutotestsLogs(idSetup, page, ordering, searchInput || null, keycloak.token)
	// 		.then((response) => setData(response.data))
	// 		.catch((error) => console.log(error))
	//     .finally(() => setIsLoading(false))
	// }, [page, ordering])
	//
	//
	// useEffect(() => {
	//   setIsLoading(true)
	// 	if (+page !== 1) {
	// 		setPage(1)
	// 	} else {
	// 		getSetupAutotestsLogs(idSetup, page, ordering, searchInput || null, keycloak.token)
	// 			.then((response) => setData(response.data))
	// 			.catch((error) => console.log(error))
	//       .finally(() => setIsLoading(false))
	// 	}
	// }, [searchInput])
	// useUnmountEffect(() => {
	// 	setUrlPage('1')
	// })
	
	const isEmptySearchQuery = isEmpty(urlSearchQuery);
	
	const tableColls = useMemo(() => getTableColls(idSetup, isEmptySearchQuery),
		[idSetup, isEmptySearchQuery])
	
	const {tableOptions, tableHeads, tableColgroup} = useMyTable(tableColls)
	
	return (
		<div>
			<SearchInput
				resetWhen={!urlSearchQuery}
				withBorder
				className={'mb-1'}
				onChange={(e) => setUrlSearchQuery(e.target.value)}
				placeholder={'Поиск по администратору, серийному номеру, keycloakID...'}
				defaultValue={urlSearchQuery}
			/>
			
      {
      isEmptySearchQuery && logsStates.isReady && logsStates.data?.count === 0 ? 
      
      <EmptyDataWithoutSearch/> :

			<MyTable colgroup={tableColgroup}
			         paginationAllCount={logsStates.data?.count}
               showEmptyFoundDataMessage={isNotEmpty(urlSearchQuery)}
			         isDataReady={logsStates.isReady}>
				
				<MyTableHead heads={tableHeads}/>
				
				<MyTableBody className={loader(logsStates.isReady)}
				             data={logsStates.data?.results}
				             highestRowToFill={45}
				             tableOptions={tableOptions}/>
			</MyTable>
      }
		</div>
	)
}