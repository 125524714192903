import React from 'react';
import ReactDOM from 'react-dom';

import 'react-toastify/dist/ReactToastify.css';
import './styles/index.scss'


import {BrowserRouter} from 'react-router-dom';
import {App} from './App';
import './services/axios/axiosConfig'
import reportWebVitals from './reportWebVitals';
import {KeycloakProvider} from './context/KeycloakProvider';

ReactDOM.render(
	<React.StrictMode>
		<KeycloakProvider>
			<BrowserRouter>
				<App/>
			</BrowserRouter>
		</KeycloakProvider>
	</React.StrictMode>
	,document.getElementById('root')
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();



// import React from 'react'
// import ReactDOM from 'react-dom';
// import {BrowserRouter} from 'react-router-dom'
// import reportWebVitals from './reportWebVitals'
//
// import 'react-toastify/dist/ReactToastify.css';
// // import './index.css'
// import {KeycloakProvider} from './keycloack/keycloakProvider'
// import {AppRoutes} from './components/AppRoutes'
//
// ReactDOM.render(
// 	<React.StrictMode>
// 		<KeycloakProvider>
// 			<BrowserRouter>
// 				<AppRoutes/>
// 			</BrowserRouter>
// 		</KeycloakProvider>
// 	</React.StrictMode>
// 	, document.getElementById('root')
// )

//
// const root = ReactDOM.createRoot(document.getElementById('root'))
// root.render(
// 	<KeycloakProvider>
// 		<React.StrictMode>
// 			<BrowserRouter>
//
// 				<ToastContainer
// 					limit={5}
// 					position={'bottom-right'}
// 					className={'w-auto'}
// 					toastClassName={'text-body rounded shadow'}
// 					bodyClassName={'px-1'}
// 					hideProgressBar
// 					closeButton={false}
// 					autoClose={2000}
// 				/>
//
// 				<AppRoutes/>
// 			</BrowserRouter>
// 		</React.StrictMode>
// 	</KeycloakProvider>
// )

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
