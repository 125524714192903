import {useUpdateEffect} from "@react-hookz/web"
import {useEffect, useMemo} from "react"
import {getSetupDeletionHistory} from "../../API/api"
import {ButtonGoBack} from "../../components/button/ButtonGoBack"
import {MyTable} from "../../components/table/MyTable/MyTable"
import {MyTableBody} from "../../components/table/MyTable/MyTableBody"
import {MyTableHead} from "../../components/table/MyTable/MyTableHead"
import {useMyTable} from "../../components/table/MyTable/useMyTable"
import {Head} from "../../components/UI/Head"
import {urlParams} from "../../constants/urlParams"
import {useComboSearch} from "../../hooks/url/useComboSearch"
import {useFetchStates} from "../../hooks/useFetchStates"
import {loader} from "../../js-helpers/helpers"
import {deletionHistoryTableOptions} from "../../components/table/MyTable/options/columnsDeviceSetupDeletionHistory"
import {FiltersPageDeletionHistory} from "./FiltersPageDeletionHistory"

const {SEARCH, PAGE, LIMIT, ADMIN, DEVICE, DATE_FROM, DATE_TO, SORT} = urlParams
const pageSearchParams = [
	{param: SEARCH, init: '', debounce: 333},
	{param: PAGE, init: 'last'},
	{param: LIMIT},
	{param: SORT},
	{param: ADMIN},
	{param: DEVICE},
	{param: DATE_FROM},
	{param: DATE_TO}
]

export const DeletionHistory = () => {
	const [
		[urlSearchQuery],
		[urlPage, setUrlPage],
		[urlLimit],
		[urlSortField],
		[urlAdmin],
		[urlDevice],
		[urlDateFrom],
		[urlDateTo],
	] = useComboSearch(pageSearchParams);
	
	// const [isPaginationReady, setIsPaginationReady] = useState(true);
	
	const requestQuery = {
		page: urlPage,
		page_size: urlLimit,
		ordering: urlSortField,
		search: urlSearchQuery,
		device_setup__admin__account__keycloak_id: urlAdmin,
		device_setup__created_at__gte: urlDateFrom,
		device_setup__created_at__lte: urlDateTo,
		device_setup__serial_number: urlDevice,
	}
	
	const deletionHistory = useFetchStates(
		(_page = urlPage) => getSetupDeletionHistory({
			...requestQuery,
			page: _page
		}), {noInitFetch: true, initData: {results: []}})
	
	useEffect(() => {
		deletionHistory.getData(urlPage);
	}, [urlPage, urlSortField]);
	
	useUpdateEffect(async () => {
		if (urlPage !== 'last') {
			setUrlPage('last');
			return;
		}
		await deletionHistory.getData('last');
	}, [urlLimit, urlDateFrom, urlDateTo, urlSearchQuery, urlAdmin, urlDevice]);
	
	const {tableHeads, tableColgroup} = useMyTable(deletionHistoryTableOptions)
	
	
	const showEmptyFoundDataMessage = useMemo(() => {
		return urlSearchQuery || urlDateFrom || urlDateTo || urlAdmin || urlDevice
	}, [urlSearchQuery, urlDateFrom, urlDateTo, urlAdmin, urlDevice])
	
	return (
		<>
			<ButtonGoBack className={'float-right'}/>
			<Head children={'История удалений настройки'}/>
			
			<FiltersPageDeletionHistory/>
			
			<MyTable colgroup={tableColgroup}
			         isDataReady={deletionHistory?.isReady}
			         showEmptyFoundDataMessage={!!showEmptyFoundDataMessage}
			         paginationAllCount={deletionHistory?.data?.count}
			>
				
				<MyTableHead heads={tableHeads}/>
				
				<MyTableBody data={deletionHistory?.data?.results}
				             tableOptions={deletionHistoryTableOptions}
				             className={loader(deletionHistory?.isReady)}
				/>
			</MyTable>
		</>
	)
}