import axios from 'axios'
import {setPreparedAxiosParams} from '../services/axios/axiosFunctions';

export const deviceAPI = {
	getUserDeviceInfo: (keycloakID) =>
		axios(`/users/${keycloakID}/device/`, {skipErrorHandling: true}),
	
	getAllDevices: (page = 1) =>
		axios('/devices/', {...setPreparedAxiosParams({page}), }),
	
	getDevices() {
		return axios('/devices/', {
			...setPreparedAxiosParams({...arguments[0]}),
		})
	},
	
	searchDevices: (page = 1, search, ordering, page_size) => {
		return axios('/devices/', {
			...setPreparedAxiosParams({page, search, ordering, page_size}),
		})
	},
	
	getDevice: (serial) => axios(`/devices/${serial}/`, ),
	
	getAllFreeDevices({page, search, page_size = 25}) {
		return axios('/devices/', {
				...setPreparedAxiosParams({...arguments[0], 'no-user': true}),
			},
		)
	},
	
	getAllLauncherVersions({page, search, page_size = 25}) {
		return axios('/launchers/', {
			...setPreparedAxiosParams(arguments[0]),
		})
	},
	
	getAllHistoriesSetup: (page = 1) =>
		axios('/devices/setup/', {...setPreparedAxiosParams({page}), }),
	
	getHistory: (id) => axios(`/devices/setup/${id}/`, ),
	
	getSections({phone_model, instruction_version}) {
		return axios('/devices/setup/sections/', {
			...setPreparedAxiosParams(arguments[0]),
			
		});
	},
	
	getSection(sequence_number, {phone_model, version_android, instruction_version}) {
		return axios(`devices/setup/sections/${sequence_number}/`,
			{...setPreparedAxiosParams(arguments[1]), }
		)
	},
	
	completeSection: steps => axios.post('devices/setup/item/', steps),
	
	getDeviceCache: id => axios(`devices/setup/${id}/cache/`),
	
	getQrTestCode: setup_id => axios.post('devices/setup/codes/', {setup_id}),
	
	patchSetupSerialNumber: (setupID, deviceSN) =>
		axios.patch(`devices/setup/${setupID}/`, {serial_number: deviceSN}),
	
	completeSetup: setup_id => axios.post(
		'devices/setup/complete/',
		{setup_id},
		{skipErrorHandling: true})
}