import {useFetchStates} from '../../../../../hooks/useFetchStates';
import {deviceAPI} from '../../../../../API/deviceAPI';
import {isEmpty} from '../../../../../js-helpers/helpers';
import {useUpdateEffect} from '@react-hookz/web';
import {useMemo} from 'react';

// usePageRequests
export default ({setupID, urlStage, setUrlStage}) => {
	const setupStates = useFetchStates(() => deviceAPI.getHistory(setupID))
	const reFetchSetup = () => setupStates.getData()
	// console.log({setupStates})
	const allSectionsCountStates = useFetchStates(
		deviceAPI.getSections,
		{setNestedField: 'count', noInitFetch: true})
	
	const currSectionStates = useFetchStates(
		(query) => deviceAPI.getSection(urlStage, query),
		{noInitFetch: true})
	
	const [currSectionQuery, completedStagesCount] = useMemo(() => {
		if (isEmpty(setupStates.data)) return [{}, 0]
		
		const {phone_model, version_android, settings, instruction} = setupStates.data
		const _currSectionQuery = {
			phone_model,
			version_android,
			instruction_version: instruction?.id
		}
		const _completedCount = isEmpty(settings)
			? 0
			: Math.max(...settings.map((item) => item.setting.section.sequence_number))
		
		return [_currSectionQuery, _completedCount]
	}, [setupStates.data])
	
	useUpdateEffect(() => {
		currSectionStates.getData(currSectionQuery)
	}, [urlStage])
	
	useUpdateEffect(async () => {
		const {data} = await allSectionsCountStates.getData(currSectionQuery)
		const maxStagesCount = data.count
		// console.log({
		// 	completedStagesCount,
		// 	maxStagesCount,
		// 	urlStage
		// })
		
		let activeStage = completedStagesCount + 1
		
		if (completedStagesCount >= maxStagesCount)
			activeStage = maxStagesCount
		
		if (!urlStage) {
			setUrlStage(activeStage)
			return
		}
		
		// activeStage === urlStage
		// 	?
		currSectionStates.getData(currSectionQuery)
		// : setUrlStage(activeStage)
	}, [setupStates.data])
	
	return {
		allSectionsCountStates,
		completedStagesCount,
		setupStates,
		currSectionStates,
		reFetchSetup
	}
}