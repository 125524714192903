import React, {useContext, useEffect, useMemo} from 'react'
import {Route, Routes, useRoutes} from 'react-router-dom'
import {PageLogin} from '../pages/PageLogin/PageLogin'
import {routes} from '../routes/routes'
import {KeycloakContext} from '../context/KeycloakProvider'
import {loggedAdmin} from '../stores/loggedAdmin';
import {observer} from 'mobx-react-lite';
import {FullScreenLoader} from '../pages/FullScreenLoader';

// const {getIsResolved} = loggedAdmin

// const permissionsFilterRoutes = (routes) => {
// 	const resolvedRoutes = (_routes) => _routes.map((_route) => {
// 		const {resolve, children} = _route
//
// 		const _getIsResolved = () =>
// 			isFunction(resolve) ? resolve() : getIsResolved(resolve)
//
// 		if (!resolve && !children)
// 			return _route
//
// 		if (!resolve && children)
// 			_route.children = resolvedRoutes(children)
//
// 		if (resolve && !_getIsResolved(resolve)) {
// 			if (children)
// 				_route.children = resolvedRoutes(children)
// 			_route.element = <NotResolvedPage/>
// 		}
// 		return _route
// 	})
//
// 	return resolvedRoutes(routes)
// }

const loadingAdminRoute = [{path: '*', element: <FullScreenLoader text={'Получение данных администратора...'}/>}]

export const AppRouter = observer(() => {
	const {adminLoggedInKC} = useContext(KeycloakContext)
	const {data, isReady, fetchAdmin, isSuperAdmin} = loggedAdmin
	
	useEffect(() => {
		adminLoggedInKC && fetchAdmin()
	}, [adminLoggedInKC])
	
	const filteredRoutes = useMemo(() => {
			if (!isReady)
				return loadingAdminRoute
			
			// return isSuperAdmin
			// 	? getRoutes()
			// 	: permissionsFilterRoutes(getRoutes())
			return routes
		}
		, [data, isSuperAdmin])
	
	const securePage = useRoutes(filteredRoutes)
	
	return (
		adminLoggedInKC
			? securePage
			:
			<Routes>
				<Route path='*' element={<PageLogin/>}/>
			</Routes>
	)
})


// import {Auth} from '../../old_src/components/auth/Auth'
// import {useContext} from 'react'
// import {KeycloakContext} from '../../old_src/keycloack/keycloakProvider'
// import {Route, Routes} from 'react-router-dom'
// import {History} from '../../old_src/components/history/History'
// import {DeviceSetup} from '../../old_src/components/deviceSetup/DeviceSetup'
// import {SetupHistory} from '../../old_src/components/history/setupHistory/SetupHistory'
// import {SetupLogs} from '../../old_src/components/SetupLogs/SetupLogs'
// import {SetupTesting} from '../../old_src/components/SetupLogs/Testing/SetupTesting/SetupTesting'
//
// export const AppRoutes = () => {
//
// 	const {logged} = useContext(KeycloakContext)
//
// 	// return (
// 	//   logged ? isPageWelcome ?
// 	//       <PageWelcome setIsPageWelcome={setIsPageWelcome}/>
// 	//       : <App/>
// 	//     : <Auth/>
// 	// )
//
// 	return (
// 		logged ?
// 			<>
// 				{/* <Header/> */}
// 				<Routes>
// 					{/* <Route path={'/'} element={<PageWelcome/>}/> */}
// 					<Route path={'/'} element={<History/>}/>
// 					<Route path={'/history'} element={<History/>}/>
// 					<Route path={'/history/:idHistory'} element={<SetupHistory/>}/>
// 					<Route path={'/history/:idSetup/logs'} element={<SetupLogs/>}/>
// 					<Route path={'/history/:idSetup/logs/:idLog'} element={<SetupTesting/>}/>
// 					<Route path={'/device_setup'} element={<DeviceSetup key={1}/>}/>
// 					<Route path={'/device_setup/:idSetup'} element={<DeviceSetup key={2}/>}/>
// 				</Routes>
// 			</> :
// 			<Auth/>
// 	)
// }