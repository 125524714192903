import dayjs from 'dayjs';
import duration from 'dayjs/plugin/duration';
import {toast} from 'react-toastify';

dayjs.extend(duration);

export const toggle = (isTrue, ifTrue = isTrue, ifFalse = '') =>
	isTrue ? ifTrue : ifFalse;

export const objSize = (obj) => {
	let size = 0,
		key;
	for (key in obj) {
		if (obj.hasOwnProperty(key)) size++;
	}
	return size;
};

export const isEmpty = (any) => {
	if (typeof any === 'boolean') return false;
	if (typeof any === 'string') return !any?.trim();
	if (typeof any === 'number') return false;
	if (Array.isArray(any)) return !any.length;
	if (typeof any === 'object') return !objSize(any);
	return !any;
};

export const isObject = (val) => val === Object(val);

//поднимает вложенные объекты одной вложенности до корневого уровня по именам ключей в toFlatFields
// {a: {b: '1'}, c: {d: '2'}} => {b: '1', d: '2'}}
export const getFlatObject = (obj, {toFlatFields} = {}) => {
	const _obj = {...obj};
	
	const newObj = toFlatFields?.reduce((acc, field) => {
		const nestedFields = _obj[field];
		delete _obj[field];
		
		return {...acc, ...nestedFields};
	}, {});
	
	return {..._obj, ...newObj};
};

export const isNotEmpty = (any) => !isEmpty(any);

export const confirmDialog = (msg) => {
	return new Promise((resolve) =>
		window.confirm(msg) ? resolve(true) : resolve(false)
	);
};

export const getTimeDiff = (d1, d2) => {
	if (!isValidDate(d1) || !isValidDate(d2)) return '-';
	
	let timeLeft = dayjs.duration(dayjs(d2).diff(dayjs(d1)));
	let finalDate = [];
	
	const asHours = Math.floor(timeLeft.asHours());
	finalDate.push(asHours || '00');
	if (asHours > 0) timeLeft = timeLeft.subtract(asHours, 'hour');
	
	const asMinutes = Math.floor(timeLeft.asMinutes());
	finalDate.push(asMinutes || '00');
	if (asMinutes > 0) timeLeft = timeLeft.subtract(asMinutes, 'minute');
	
	const asSeconds = Math.floor(timeLeft.asSeconds());
	finalDate.push(asSeconds || '00');
	
	return finalDate.map((part) => String(part).padStart(2, '0')).join(':');
};

export const translateThisBullshit = (text, {female} = {}) => {
	/* переводит некоторые слова в нужный язык */
	const data = {
		unknown: 'Неизвестный',
	};
	
	let translatedText = data[text.toLowerCase()];
	
	if (female && translatedText)
		translatedText = translatedText.replace('ый', 'ая').replace('ий', 'ая');
	
	return translatedText || text;
};

export const isValidDate = (date) => (!date ? false : dayjs(date).isValid());

export const getDateNow = () => new Date(Date.now());

export const getEndCurrentDay = () =>
	dayjs().hour(23).minute(59).second(59).toISOString();

export const getIsoUtcDate = (date) =>
	new Date(new Date(date).toUTCString()).toISOString();

export const getLocaleFullDate = (date) =>
	toggle(isValidDate(date), new Date(date).toLocaleString(), '-');

export const getLocaleDate = (date) =>
	toggle(isValidDate(date), new Date(date).toLocaleDateString(), '-');

export const getLocaleTime = (date) =>
	toggle(isValidDate(date), new Date(date).toLocaleTimeString(), '-');

export const getFileNameFormatDate = (date) =>
	getLocaleFullDate(date).replace(/[.:]/g, '-').replace(', ', '_');

export const getFileNameWithDatesRange = (
	dateFrom,
	dateTo,
	prependText = ''
) => {
	let extraDates = '';
	
	if (dateFrom) extraDates += `_c_${getFileNameFormatDate(dateFrom)}`;
	
	if (dateTo) extraDates += `_по_${getFileNameFormatDate(dateTo)}`;
	
	return prependText + extraDates;
};

export const getBoolFromString = (str, {invert}) => {
	const bool = {true: true, false: false};
	const invertBool = {false: true, true: false};
	
	return invert ? invertBool[str] : bool[str];
};

export const getInputLocalFullDate = (date, minTime = false) => {
	if (!isValidDate(date)) return '-';
	
	const pad = (number) => {
		const str = String(number);
		return str.length < 2 ? '0' + str : str;
	};
	
	const _date = new Date(date);
	const yy = _date.getFullYear();
	const mm = _date.getMonth() + 1;
	const day = _date.getDate();
	const hh = minTime ? 0 : _date.getHours();
	const min = minTime ? 0 : _date.getMinutes();
	
	return `${yy}-${pad(mm)}-${pad(day)}T${pad(hh)}:${pad(min)}`;
};

//проверяет на хотя бы 1 равенство a и элементов массива
export const isEqualSome = (a, arr = []) => arr.some((val) => val === a);

export const includeSomeSubstring = (string, arr = []) =>
	arr.some((subStr) => string.includes(subStr));

export const getShortString = (string, maxLength = 20, {startTrim} = {}) => {
	if (!string) return '';
	const length = string.length;
	if (length <= maxLength) return string;
	
	return startTrim
		? `...${string.substring(length - maxLength)}`
		: `${string.substring(0, maxLength)}...`;
};

export const isFunction = (val) => typeof val === 'function';

export const isString = (val) => typeof val === 'string';

export const getFullNameFromObj = (userData) => {
	if (!userData) return '-';
	const {first_name, middle_name, last_name} = userData;
	
	return `${last_name} ${first_name}\u{00A0}${middle_name}`;
};

export const getShortFullName = (first_name, middle_name, last_name) => {
	if (isEmpty(last_name)) return `${first_name} ${middle_name}`;
	return `${last_name}\u{00A0}${first_name && first_name[0]}.\u{00A0}${
		middle_name && middle_name[0]
	}.`;
};

export const getShortFullNameFromObj = (userData) => {
	if (!userData) return '-';
	
	const {first_name, middle_name, last_name} = userData;
	
	if (isEmpty(last_name)) return `${first_name}\u{00A0}${middle_name}`;
	
	const getShort = (name) => toggle(name, `${name[0]}.`);
	return `${last_name}\u{00A0}${getShort(first_name)}\u{00A0}${getShort(
		middle_name
	)}`;
};

export const isLastItem = (ind, length) => ind === length - 1;

export const loader = (hide, {size} = {}) => {
	const finalStyle = `${toggle(size === 'small', 'small ')}${toggle(
		size === 'large',
		'large '
	)}${toggle(!hide, 'show')}`;
	
	return `c-loading ${finalStyle}`;
};

export const blockUI = (isBlock) => toggle(isBlock, 'c-block-ui');

export const debounce = function (func, timeout = 300) {
	let timer;
	return (...args) => {
		clearTimeout(timer);
		timer = setTimeout(() => {
			func.apply(this, args);
		}, timeout);
	};
};

export const getFullLauncherVersion = (version_name, version_code) =>
	`${version_name} (${version_code})`;

export const getFullLauncherVersionFromObj = ({
	                                              launcher_version_name,
	                                              launcher_version_code,
                                              }) =>
	!launcher_version_name && !launcher_version_code
		? '-'
		: `${launcher_version_name || '-'} (${launcher_version_code || '-'})`;

export const getBrandAndModelFromObj = ({brand = '-', model = '-'}) =>
	`${capitalize(brand)} ${model}`;

export const capitalize = (string) =>
	string[0].toUpperCase() + string.substring(1);

export const isEqualObjects = (firstObj, secondObj) => {
	const sameLength =
		Object.keys(firstObj).length === Object.keys(secondObj).length;
	return (
		Object.entries(firstObj).every(
			([key, value]) => secondObj[key] === value
		) && sameLength
	);
};

export const isEqualArrays = (firstArr, secondArr) => {
	const sameLength = firstArr.length === secondArr.length;
	if (!sameLength) return false;
	
	const [sortedFirstArr, sortedSecondArr] = [[...firstArr], [...secondArr]].map(
		(arr) => arr.sort()
	)
	
	return (
		sameLength &&
		sortedFirstArr.every((value, index) => value === sortedSecondArr[index])
	);
};

export const getAppName = (app) => {
	const appObj = {
		1: 'Launcher Service',
		2: 'Screenshots Service',
		3: 'Monitor Apps Service',
		4: 'Contacts Service',
		5: 'Calls Recorder Service',
	};
	return appObj[app];
};

export const autoChangeHeight = (element, defaultHeight, extraHeight) => {
	if (element) {
		const target = element.target ? element.target : element;
		target.style.height = defaultHeight;
		target.style.height = `${target.scrollHeight + extraHeight}px`
	}
}

export function showErrToast(data) {
	if (isEmpty(data))
		return
	
	const textErrors = isObject(data) ? Object.values(data).join(', ') : data
	toast(textErrors, {type: 'error'})
}

