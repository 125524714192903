import React, {useRef} from 'react';
import uniqid from 'uniqid';
import {toggle} from '../../js-helpers/helpers';

export function CheckBox(
	{
		name,
		checked,
		disabled,
		onChange,
		className = '',
		appendLabel,
		prependLabel,
		defaultChecked,
		white,
		...props
	}) {
	if (checked && !onChange)
		onChange = () => undefined
	
	const inputProps = {disabled, defaultChecked, name, onChange, checked, ...props}
	const idRef = useRef(uniqid())
	
	const extraMargins =
		prependLabel && appendLabel ? 'mx-75'
			: prependLabel ? 'ml-75'
				: appendLabel ? 'mr-75' : ''
	
	return (
		<label
			className={`text-c-transform-none text-body font-small-3 m-0 ${toggle(!disabled, 'cursor-pointer min-c-touch-zone')} ${className}`}>
			
			{prependLabel}
			
			<fieldset className={`checkbox ${extraMargins} ${toggle(!white, 'checkbox-primary')}`}>
				
				<input id={idRef.current}
				       type={'checkbox'}
				       tabIndex={0}
				       {...inputProps}/>
				
				<label htmlFor={idRef.current}
				       className={`${toggle(!disabled, 'cursor-pointer')} align-middle`}/>
			</fieldset>
			
			{appendLabel}
		
		</label>
	)
}

