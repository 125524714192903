export const smassAdminPermissions = {
	ADMINS: 'admins',
	DIVISIONS: 'divisions',
	CONTACTS: 'contacts',
	DEVICES: 'devices',
	APPS: 'apps',
	FEEDBACKS: 'feedbacks',
	CRASHLYTICS: 'crashlytics',
	CALLS: 'calls',
	MAILINGS: 'mailings',
  NOTIFICATIONS: 'notifications',
	LOGS: 'logs',
	GLOBAL_SEARCH: 'global_search',
	
	USERS: 'users',
	USERS_DETAILS: 'users_details',
	USERS_CALLS: 'users_calls',
	USERS_MESSAGES: 'users_messages',
	USERS_LOGS: 'users_logs',
	USERS_MEDIA: 'users_media',
	USERS_PHONEBOOK: 'users_phonebook',
	USERS_SETTINGS: 'users_settings',
	USERS_TRACKERS: 'users_trackers'
}

export const setupAdminPermissions = {
	ADMINS: 'admins',
	LOGS: 'logs',
	SETUPS: 'setup',
  DELETION_HISTORY: 'deletion_history'
}

export const getLoggedAdminPermissions = (is_superadmin) => (
	is_superadmin ? [] : [setupAdminPermissions.SETUPS]
)



