import {Navigate} from 'react-router-dom';
import {AppLayout} from '../components/layout/AppLayout';
import {NotResolvedPage} from '../pages/notResolvedPage/NotResolvedPage';
import {NotFoundPage} from '../pages/notFoundPage/NotFoundPage';
import {SetupsHistoryPage} from '../pages/history/SetupsHistoryPage';
import {SetupLogs} from '../pages/SetupLogs/SetupLogs';
import {SetupTesting} from '../pages/SetupLogs/Testing/SetupTesting/SetupTesting';
import {MenuAdmin} from '../pages/menuAdmin/MenuAdmin';
import {PageAdminEdit} from '../pages/members/pageAdmins/pageAdminEdit/PageAdminEdit';
import {setupAdminPermissions} from '../constants/smassAdminPermissions';
import {PageAdmins} from '../pages/members/pageAdmins/PageAdmins';
import {PageDeviceSetupInfo} from '../pages/pageSetupHistory/PageDeviceSetupInfo';
import {PageDeviceSetuping} from '../pages/pageSetupHistory/PageDeviceSetuping/PageDeviceSetuping';
import {DeletionHistory} from '../pages/deletionHistory/DeletionHistory';

const {ADMINS, SETUPS, LOGS, DELETION_HISTORY} = setupAdminPermissions

export const routes = [
	{
		element: <AppLayout/>,
		children: [
			// {path: '', element: <SetupHistoryPage/>},
			{path: '', element: <MenuAdmin/>},
			{
				path: '/admins/',
				children: [
					{path: '', resolve: ADMINS, element: <PageAdmins/>},
					{path: ':keycloakID/', resolve: ADMINS, element: <PageAdminEdit/>},
				],
			}, {
				path: '/history/',
				resolve: LOGS,
				children: [
					{path: '', element: <SetupsHistoryPage/>},
					{path: ':setupID/', element: <PageDeviceSetupInfo/>},
					{path: ':idSetup/logs/', element: <SetupLogs/>},
					{path: ':idSetup/logs/:idLog/', element: <SetupTesting/>,}
				]
			},
			// {path: '/device_setup/', resolve: SETUPS, element: <DeviceSetup key={1}/>},
			{path: '/device_setup/:setupID/', resolve: SETUPS, element: <PageDeviceSetuping/>},
			{path: '/deletion_history/', resolve: DELETION_HISTORY, element: <DeletionHistory/>},
			{path: '/403/', element: <NotResolvedPage/>},
			{path: '/404/', element: <NotFoundPage/>},
		],
	}, {
		path: '*',
		element: <Navigate to='' replace/>,
// element: <Navigate to="/404/" replace />,
	},
];

// <Route path={'/'} element={<History/>}/>
// <Route path={'/history'} element={<History/>}/>
// <Route path={'/history/:idHistory'} element={<SetupHistory/>}/>
// <Route path={'/history/:idSetup/logs'} element={<SetupLogs/>}/>
// <Route path={'/history/:idSetup/logs/:idLog'} element={<SetupTesting/>}/>
// <Route path={'/device_setup'} element={<DeviceSetup key={1}/>}/>
// <Route path={'/device_setup/:idSetup'} element={<DeviceSetup key={2}/>}/>

// export const getRoutes = () => routes;
