import {useMemo} from 'react';
import {useParams} from 'react-router-dom';
import {BtnGoBack} from '../history/setupHistory/BtnGoBack';
import {LogsIcon} from './LogsIcon';
import {QrLogs} from './QrLogs/QrLogs';
import {Testing} from './Testing/Testing';
import {Head} from '../../components/UI/Head';
import {useUpdateEffect} from '@react-hookz/web';
import {useComboSearch} from '../../hooks/url/useComboSearch';
import {urlParams} from '../../constants/urlParams';
import classNames from 'classnames';

const LOGS_SERVICES = {
	logs: 'logs',
	test: 'test',
}

const setupLogsOptions = [
	{
		head: 'Генерация QR-кода',
		service: LOGS_SERVICES.logs,
		icon: 'logs',
		pageHead: 'Просмотр логов - Генерация QR-кода',
	}, {
		head: 'Тестирование',
		service: LOGS_SERVICES.test,
		icon: 'test',
		pageHead: 'Просмотр логов - Тестирование',
	},
]

const {PAGE, TYPE} = urlParams

const pageSearchParams = [
	{param: PAGE},
	{param: TYPE, init: LOGS_SERVICES.logs}
]

export const SetupLogs = () => {
	const {idSetup} = useParams()
	
	const [
		[_, setUrlPage],
		[urlType, setUrlType],
	] = useComboSearch(pageSearchParams)
	
	const pageHead = useMemo(() => {
		const option = setupLogsOptions.find(
			(option) => option.service === urlType)
		return option.pageHead
	}, [urlType])
	
	useUpdateEffect(() => {
		setUrlPage(1)
	}, [urlType])
	
	return (
		<>
			<BtnGoBack className={'float-right'}/>
			<Head>{pageHead}</Head>
			
			<div className={'row'}>
				<ul className={'list-group list-unstyled mr-2'}>
					{setupLogsOptions.map(({icon, head, service}) => (
						<li key={service}>
							<button
								className={classNames(
									'btn w-100 d-flex align-items-center text-left',
									{'bg-white text-body': urlType === service}
								)}
								onClick={() => { setUrlType(service) }}
							>
								<LogsIcon type={icon} className={'mr-1'}/>
								{head}
							</button>
						</li>
					))}
				</ul>
				
				<div className={'col'}>
					{urlType === LOGS_SERVICES.logs && <QrLogs idSetup={idSetup}/>}
					{urlType === LOGS_SERVICES.test && <Testing idSetup={idSetup}/>}
				</div>
			</div>
		</>
	);
};
