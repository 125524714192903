import {AxiosInterceptorsHelper} from './services/axios/interceptor/AxiosInterceptorsHelper';
import {ToastContainer} from 'react-toastify';
import {limitsStore} from './stores/limitsOptionsStore';
import {useEventListener} from '@react-hookz/web';
import relativeTime from 'dayjs/plugin/relativeTime';
import 'dayjs/locale/ru';
import dayjs from 'dayjs';
import {ErrorBoundary} from 'react-error-boundary';
import {InsideErrorPage} from './pages/InsideErrorPage/InsideErrorPage';
import {AppRouter} from './routes/AppRouter';
import {MyModalContainer} from './components/MyModal/MyModalContainer';

dayjs.extend(relativeTime);
dayjs.locale('ru');

export function App() {
	useEventListener(window, 'beforeunload', () => {
		limitsStore.saveLimits();
	});
	
	return (
		<>
			<AxiosInterceptorsHelper/>
			
			<ErrorBoundary FallbackComponent={InsideErrorPage}>
				<div className={'app'}>
					<AppRouter/>
				</div>
			</ErrorBoundary>
			
			<ToastContainer
				limit={5}
				position={'bottom-right'}
				className={'w-auto font-small-3'}
				toastClassName={'text-body rounded shadow-sm'}
				bodyClassName={'px-1'}
				toastStyle={{minHeight: '3rem'}}
				hideProgressBar
				closeButton={false}
				autoClose={3000}
			/>
			
			<MyModalContainer/>
		</>
	);
}
