import {createElement, forwardRef} from 'react';
import {Head} from './Head';
import PropTypes from 'prop-types';

const classes = {
	basic: 'bg-white',
	secondary: 'bg-c-rgba-light',
}

const paddings = {
	0: '',
	1: 'px-1 py-1 ',
	2: 'px-2 py-1 ',
	3: 'px-3 py-2 ',
}

export const Card = forwardRef((
	{
		children,
		size = 2,
		secondary,
		title,
		className = '',
		as = 'div',
		titleUppercase,
		showOnlyChildren,
		...props
	}, ref) => {
	
	const finalClassName = secondary
		? classes.secondary
		: classes.basic
	
	const preparedChildren =
		<>
			{title &&
			<Head secondary
			      uppercase={titleUppercase}
			      noMargins
			      className={'mb-1'}>
				{title}
			</Head>
			}
			
			{children}
		</>
	
	if (showOnlyChildren)
		return preparedChildren
	
	return createElement(
		as,
		{className: `rounded-lg ${finalClassName} ${paddings[size]} ${className}`, ref, ...props},
		preparedChildren
	)
})

Card.propTypes = {
	size: PropTypes.oneOf([0, 1, 2, 3]),
}