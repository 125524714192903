import {useClickOutside, useKeyboardEvent} from '@react-hookz/web';
import {closeMyModal} from '../../MyModal/showModal';
import {useRef} from 'react';
import {Card} from '../../UI/Card';
import {Head} from '../../UI/Head';
import classNames from 'classnames';
import {Button} from '../../button/Button';


function _Container({className, ...props}) {
	const modalRef = useRef()
	useKeyboardEvent('Escape', () => closeMyModal())
	useClickOutside(modalRef, closeMyModal);
	
	return (
		<Card ref={modalRef}
		      size={0}
		      className={classNames('pt-3 text-wrap shadow-sm', className)}>
			{props.children}
		</Card>
	)
}

function _Head({className, ...props}) {
	return (
		<Head secondary className={'text-center text-bold-600 px-1'}>
			{props.children}
		</Head>
	)
}

function _Body({className, ...props}) {
	return (
		<div className={classNames('p-3', className)} style={{minWidth: '600px', maxWidth: '100vw'}}>
			{props.children}
		</div>
	)
}

function _Footer(
	{
		onCancelClick,
		onSuccessClick,
		cancelText = 'Отмена',
		successText = 'Сохранить',
		successDisabled = false,
	}) {
	return (
		<div className={'bg-c-gray-50 py-2 px-3 text-center'}
		     style={{borderBottomLeftRadius: 'inherit', borderBottomRightRadius: 'inherit',}}
		>
			<button onClick={onCancelClick} className={'btn btn-outline-light mr-3'}>
				{cancelText}
			</button>
			
			<Button save disabled={successDisabled} onClick={onSuccessClick}>
				{successText}
			</Button>
		</div>
	)
}


export const modal = ({
	Container: _Container,
	Head: _Head,
	Body: _Body,
	Footer: _Footer
})



