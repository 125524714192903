import ReactDOM from 'react-dom';


export const showMyModal = (
	{
		onSuccess,
		onAbort,
		// variant,
		// confirmModalProps: {head, body,} = {},
		// color = 'primary',
		// common: {text, bool} = {}
		RenderModal,
		...props
	} = {}) => {
	
	// const _onAbort = () => {
	// 	closeModal()
	// 	onAbort?.()
	// }
	
	// console.log({text})
	// const modals = {
	// delete:
	// 	<MyModal
	// 		toggle={_onAbort}
	// 		head={'Удаление'}
	// 		color={'danger'}
	// 		body={text}
	// 		footer={
	// 			<>
	// 				<MyBtn variant={'cancel'} className={'me-2'} color={'primary'} onClick={_onAbort}/>
	// 				<MyBtn variant={'delete'}
	// 				       onClick={() => onSuccess().then(() => {_onAbort()})}/>
	// 			</>
	// 		}
	// 	/>,
	// logout:
	// 	<MyModal
	// 		toggle={_onAbort}
	// 		head={'Выход'}
	// 		color={'danger'}
	// 		body={'Выйти?'}
	// 		footer={
	// 			<>
	// 				<MyBtn variant={'cancel'} className={'me-2'} color={'primary'} onClick={_onAbort}/>
	// 				<MyBtn color={'outline-primary'}
	// 				       onClick={() => onSuccess().then(() => {_onAbort()})}>
	// 					Выйти
	// 				</MyBtn>
	// 			</>
	// 		}
	// 	/>,
	// confirm:
	// 	<MyModal
	// 		toggle={_onAbort}
	// 		head={head}
	// 		color={color}
	// 		body={body}
	// 		footer={
	// 			<>
	// 				<MyBtn variant={'cancel'} className={'me-2'} onClick={_onAbort}/>
	// 				<MyBtn variant={'save'}
	// 				       onClick={() => onSuccess().then(() => {_onAbort()})}>
	// 					Да
	// 				</MyBtn>
	// 			</>
	// 		}
	// 	/>,
	// }
	
	// const Modal = modals[variant]
	// RenderModal.props
	// console.log(RenderModal)
	
	ReactDOM.render(
		<div className={'modal-c-backdrop'}>
			{RenderModal}
		</div>,
		document.getElementById('my-modal-container')
	)
}

export const closeMyModal = () => {
	ReactDOM.unmountComponentAtNode(document.getElementById('my-modal-container'))
}



