import {
	getDateNow,
	getIsoUtcDate,
	isEmpty,
	isEqualArrays,
	isNotEmpty,
	showErrToast
} from '../../../../../js-helpers/helpers';
import {useImmer} from 'use-immer';
import {useUpdateEffect} from '@react-hookz/web';
import {useRef} from 'react';
import {closeMyModal, showMyModal} from '../../../../../components/MyModal/showModal';
import {ModalDialog} from '../../../../../components/modals/ModalDialog';
import {useFetchStates} from '../../../../../hooks/useFetchStates';
import {deviceAPI} from '../../../../../API/deviceAPI';
import {toast} from 'react-toastify';
import {useNavigate} from 'react-router-dom';
import { serverErrorsStatuses } from '../../../../../services/axios/interceptor/showRequestErrorMessage';

// useUnsavedSteps
export default (
	{
		setupID,
		urlStage,
		setUrlStage,
		isSavedStage,
		reFetchSetup,
		currSectionData,
	}) => {
	const allStepsIDS = useRef([])
	
	useUpdateEffect(() => {
		if (isSavedStage) {
			allStepsIDS.current = []
			return
		}
		
		(function setAllStepsIDS() {
			const _IDs = currSectionData?.setting
				.reduce((acc, {id, subitem}) => {
					acc.push(id)
					isNotEmpty(subitem) && acc.push(...subitem.map(({id}) => id))
					return acc
				}, [])
			
			allStepsIDS.current = _IDs
		}())
	}, [currSectionData])
	
	const [unsavedSetups, setUnsavedSetups] = useImmer({})
	
	useUpdateEffect(() => {
		//clear
		setUnsavedSetups({})
	}, [urlStage])
	
	const saveStepsStates = useFetchStates(
		deviceAPI.completeSection,
		{noInitFetch: true, initReady: true})
	
	const toggleChecked = ({checked, step}) => {
		setUnsavedSetups(draft => {
			const {id, sequence_number, subitem} = step
			const isSubItemStep = typeof subitem === 'number'
			
			checked
				? draft[id] = {
					created_at: getIsoUtcDate(getDateNow()),
					setting: {id, sequence_number, subitem: isSubItemStep ? subitem : 0}
				}
				: delete draft[id]
		})
	}
	
	const getIsAllStepsCompleted = () => {
		const unsavedIDs = Object.keys(unsavedSetups).map(Number)
		return isNotEmpty(unsavedIDs) && isEqualArrays(allStepsIDS.current, unsavedIDs);
	}
	
	async function saveSteps() {
		const requestData = {
			setting_history:
				Object.values(unsavedSetups)
					.map(({created_at, setting}) => ({
						created_at,
						setting: setting.id,
						setup_device: setupID
					}))
		}
		return await saveStepsStates.getData(requestData)
	}
	
	const finishSetupStates = useFetchStates(
		() => deviceAPI.completeSetup(setupID),
		{noInitFetch: true, initReady: true})
	
	const navigate = useNavigate()
	
	const onFinishSetup = async () => {
		try {
			if (!isSavedStage) {
				await saveSteps()
				toast.success('Данные сохранены')
				reFetchSetup()
			}
			await finishSetupStates.getData()
			toast.success('Настройка завершена успешно!')
			navigate('/history/')
		} catch (e) {
      if (e?.response?.data?.errors?.[0]?.detail) {
        if (e?.response?.data?.errors?.[0]?.code === 'unique') {
          showErrToast('Настройка с таким Серийным номером уже существует!')
        } else {
          showErrToast(e?.response?.data?.errors?.[0]?.detail)
        }
      } else {
        showErrToast(serverErrorsStatuses['500'])
      }
		}
	}
	
	const onStageChange = async (stage) => {
		if (stage === urlStage) return
		
		if (isSavedStage || isEmpty(unsavedSetups)) {
			setUrlStage(stage)
			return
		}
		
		if (getIsAllStepsCompleted()) {
			try {
				await saveSteps()
				// toast.success('Данные сохранены')
				setUrlStage(stage)
				reFetchSetup()
			} catch (e) {
        if (e?.response?.data?.errors?.[0].code === 'does_not_exist' || e?.response?.data?.errors?.[0].code === 'not_found') {
          showErrToast('Ошибка сохранения. Возможно, настройка была удалена')
          navigate('/history/')
        } else {
          showErrToast(e?.response?.data?.errors?.[0]?.detail)
        }
			}
			return
		}
		
		if (!getIsAllStepsCompleted()) {
			showMyModal({
				RenderModal:
					<ModalDialog
						head={'Внимание!'}
						bodyClassName={'text-center c-text-pre-wrap'}
						body={`Текущий прогресс этапа будет утерян, \n продолжить?`}
						showFooter
						footerProps={{
							onSuccessClick: () => {
								setUrlStage(stage)
								closeMyModal()
							},
							successText: 'Продолжить',
							onCancelClick: closeMyModal
						}}
					/>
			})
		}
	}
	
	const SetupFinishBtn = () =>
		<button onClick={onFinishSetup}
		        disabled={!isSavedStage && !getIsAllStepsCompleted()}
		        className={'btn btn-primary float-right'}>
			Завершить
		</button>
	
	return {
		toggleChecked,
		unsavedSetups,
		onStageChange,
		isReadySaveSteps: saveStepsStates.isReady,
		isReadyFinishSetup: finishSetupStates.isReady,
		SetupFinishBtn,
	}
}