import { memo } from 'react';
import { useNavigate } from 'react-router-dom';

export const BtnGoBack = memo(({className = '', onClick}) => {
  const navigate = useNavigate();
	
	const _onClick = () => {
		onClick ? onClick() : navigate(-1)
	}
	
	return (
    <button type='button'
            onClick={_onClick}  
            className={`btn btn-outline-primary border-c-transparent bg-white btn float-right ${className}`}>
      Назад
    </button>
	)
})