import React from 'react';

export function MySelectToggleButton({children, buttonProps}) {
	const {
		disabled, titleClassName = '', getToggleButtonProps = () => {},
	} = buttonProps
	
	return (
		<button disabled={disabled}
		        className={`form-control dropdown-toggle text-left text-c-crop ${titleClassName}`}
		        {...getToggleButtonProps()}
		>
			{children}
			{/*// && getShortString(children.toString(), 32)}*/}
		</button>
	)
}