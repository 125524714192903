import {useEffect, useRef, useState} from 'react'
import {toggle} from '../../../js-helpers/helpers';

const createStages = (count) => {
	return new Array(count).fill(null).map((_, index) => index + 1)
}

export const DeviceSetupInfoStagesNav = (
	{
		countStages,
		currentStage,
		maxStageCompleted,
		setCurrentStage,
		className = ''
	}) => {
	const [stages, setStages] = useState([])
	
	const [isMouseDown, setIsMouseDown] = useState(null)
	const [transformSection, setTransformSection] = useState(0)
	const [initialClick, setInitialClick] = useState(null)
	const [prevPosition, setPrevPosition] = useState(0)
	
	const handleEvent = (event) => {
		if (event.type === 'mousedown') {
			setInitialClick(event.clientX)
			setIsMouseDown(true)
			
		} else {
			setPrevPosition(transformSection)
			setIsMouseDown(false)
		}
	}
	
	const handleMouseMove = (event) => {
		if (isMouseDown) {
			const positionTransForm = prevPosition + (event.clientX - initialClick)
			const isRightLimit =
				(refSwiper.current.offsetWidth + positionTransForm) <
				refSection.current.offsetWidth
			
			if (positionTransForm > 0 || isRightLimit) return
			
			setTransformSection(positionTransForm)
		}
	}
	
	const refSwiper = useRef()
	const refSection = useRef()
	
	useEffect(() => {
		setStages(createStages(countStages))
	}, [countStages])
	
	return (
		<nav ref={refSection}
		     className={'height-75 user-select-none cursor-pointer position-relative overflow-hidden '}
		     onMouseUp={handleEvent}
		     onMouseLeave={handleEvent}
		     onMouseMove={handleMouseMove}
		     onMouseDown={handleEvent}>
			
			<ul ref={refSwiper}
			    className={`d-flex list-unstyled position-absolute ${className}`}
			    style={{left: `${transformSection}px`}}>
				
				{stages.map((stage) => {
					const isStageReady = (maxStageCompleted ?? currentStage) >= stage
					const isActive = currentStage === stage
					
					return (
						<li key={stage}>
							
							<button onClick={() => setCurrentStage(stage)}
							        className={`btn px-1 py-50 rounded hover-c-gray-50 ${
										  toggle(isActive, 'bg-c-gray-50')
									  }`}
							>
								<div className={'d-flex align-items-center pb-50 '}>
									
									<div style={{aspectRatio: '1/1', lineHeight: '2.5em', height: '2.5em'}}
									     className={`text-center rounded ${
										     isStageReady
											     ? 'bg-primary border-primary text-white'
											     : 'border-secondary text-secondary'
									     } `}>
										{stage}
									</div>
									
									<span className={`px-1 ${isStageReady ? 'text-primary' : 'text-secondary'}`}>
											Этап
									</span>
								</div>
								
								<div className={`rounded ${
									isStageReady
										? 'border-primary bg-primary'
										: 'border-secondary bg-secondary'
								}`}/>
							</button>
						</li>
					)
				})}
			</ul>
		</nav>
	)
}