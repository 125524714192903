import {useMountEffect, useToggle, useUpdateEffect} from '@react-hookz/web';
import dayjs from 'dayjs';
import {useSearchParams} from 'react-router-dom';
import {useImmer} from 'use-immer';
import {adminAPI} from '../../API/adminAPI';
import {ButtonFilter} from '../../components/button/ButtonFilter';
import {FilterPills} from '../../components/FilterPills/FilterPills';
import {MySearchSelect} from '../../components/form/MySelect/MySearchSelect';
import {RangeDatePicker} from '../../components/form/RangeDatePicker';
import {SearchInput} from '../../components/form/SearchInput';
import {urlParams} from '../../constants/urlParams';
import {useComboSearch} from '../../hooks/url/useComboSearch';
import {useFetchStates} from '../../hooks/useFetchStates';
import {getFullNameFromObj, getIsoUtcDate, getLocaleFullDate, toggle,} from '../../js-helpers/helpers';

const {SEARCH, DATE_FROM, DATE_TO, ADMIN, DEVICE, PAGE, LIMIT, SORT} =
	urlParams;

const pageSearchParams = [
	{param: SEARCH, debounce: 333},
	// {param: PAGE, init: 1, paramHandler: Number},
	// {param: LIMIT},
	// {param: SORT},
	{param: ADMIN},
	{param: DEVICE},
	{param: DATE_FROM},
	{param: DATE_TO},
];

export const FiltersPageDeletionHistory = () => {
	const [
		[urlSearchQuery, setUrlSearchQuery],
		// [urlPage],
		// [urlLimit],
		// [urlSortField],
		[urlAdmin, setUrlAdmin],
		[urlDevice, setUrlDevice],
		[urlDateFrom, setUrlDateFrom],
		[urlDateTo, setUrlDateTo],
	] = useComboSearch(pageSearchParams);
	
	const [showFilters, toggleShowFilters] = useToggle(
		urlAdmin || urlDevice || urlDateFrom || urlDateTo
	);
	
	useUpdateEffect(() => {
		if (!showFilters) {
			setUrlAdmin('');
			setUrlDevice('');
			setUrlDateFrom('');
			setUrlDateTo('');
			setInitFilters((draft) => {
				draft.admin = {
					text: '',
					value: '',
				};
				draft.device = {
					text: '',
					value: '',
				};
			});
		}
	}, [showFilters]);
	
	const [search, setSearch] = useSearchParams();
	
	const [pillsOptions, setPillsOptions] = useImmer(() => [
		{
			urlKey: SEARCH,
			label: null,
		}, {
			urlKey: ADMIN,
			label: null,
		},
     {
			urlKey: DEVICE,
			label: null,
		},
     {
			urlKey: DATE_FROM,
			label: (date) => `От  ${getLocaleFullDate(date)}`,
		}, {
			urlKey: DATE_TO,
			label: (date) => `До  ${getLocaleFullDate(date)}`,
		},
	]);
	
	const [initFilters, setInitFilters] = useImmer({
		admin: null,
		device: null,
	});
	
	const customSetter = ({data: {account}}) => {
		const text = `${getFullNameFromObj(account)}`;
		
		setPillsOptions((draft) => {
			draft.find((_pill) => _pill.urlKey === ADMIN).label = text;
		});
		
		setInitFilters((draft) => {
			draft.admin = {
				text,
				value: account.keycloak_id,
			};
		});
		initAdminState.setReady(true);
	};
	
	const initAdminState = useFetchStates(() => adminAPI.getInfo(urlAdmin), {
		noInitFetch: true,
		customSetter,
	});
	
	useMountEffect(() => {
		urlAdmin && initAdminState.getData();
    urlDevice && 	setInitFilters((draft) => {
			draft.device = {
				text: urlDevice,
				value: urlDevice,
			};
		});
	});
	
	const onAdminSelect = (selectedOption) => {
		if (!selectedOption) return;
		const {text, value} = selectedOption;
		
		setUrlAdmin(value);
		setPillsOptions((draft) => {
			draft.find((_pill) => _pill.urlKey === ADMIN).label = text;
		});
		initAdminState.setReady(true);
	};
	
	const onDeviceSelect = (selectedOption) => {
		if (!selectedOption) return;
		const {text, value} = selectedOption;
	
		setUrlDevice(value);
		setPillsOptions((draft) => {
			draft.find((_pill) => _pill.urlKey === DEVICE).label = text;
		});
	};
	
	const onClosePill = (urlKey) => {
		search.delete(urlKey);
		setSearch(search.toString(), {replace: true});
	};
	
	return (
		<>
			<div
				className={
					'row no-gutters text-muted gap-1r align-items-center mb-50 px-1 py-75 rounded bg-transparent-60'
				}
			>
				<div className={'col-12 row no-gutters align-items-center'}>
					<SearchInput
						resetWhen={!urlSearchQuery}
						className={'col mr-1'}
						onChange={(e) => setUrlSearchQuery(e.target.value)}
						placeholder={'Поиск...'}
						defaultValue={urlSearchQuery}
					/>
					
					<ButtonFilter
						showFilters={showFilters}
						toggleShowFilters={toggleShowFilters}
					/>
				</div>
				
				{showFilters && (
					<div
						className={
							'row col-12 no-gutters justify-content-between align-items-center gap-1r'
						}
					>
						<MySearchSelect
							className={'col'}
							type={'admins'}
							// init={urlAdmin && initAdminState.isReady}
							initialSelectedItem={initFilters.admin}
							resetWhen={!urlAdmin}
							onSelect={onAdminSelect}
							initText={'Все администраторы'}
							titleClassName={`${toggle(!urlAdmin, 'text-muted')}`}
						/>
						
						<MySearchSelect
							className={'col'}
							type={'allDevices'}
							init={urlDevice}
							initialSelectedItem={initFilters.device}
							resetWhen={!urlDevice}
							onSelect={onDeviceSelect}
							initText={'Все устройства'}
							titleClassName={`${toggle(!urlDevice, 'text-muted')}`}
						/>
						
						<RangeDatePicker
							prependLabel={'Период начала настройки:'}
							fromPickerProps={{
								value: urlDateFrom,
								onChange: ([date]) => {
									setUrlDateFrom(date && getIsoUtcDate(date));
								},
								options: {maxDate: urlDateTo},
							}}
							toPickerProps={{
								value: urlDateTo,
								onChange: ([date]) => {
									setUrlDateTo(
										date && getIsoUtcDate(dayjs(date).second(59).toDate())
									);
								},
								options: {minDate: urlDateFrom},
							}}
						/>
					</div>
				)}
			</div>
			
			<FilterPills
				pillsOptions={pillsOptions}
				search={search}
				onClosePill={onClosePill}
			/>
		</>
	);
};
