import {useEffect} from "react"
import {getSetupCodeLogs} from "../../../API/api"
import {getFullNameFromObj, getLocaleFullDate, getShortFullNameFromObj, isEmpty, isNotEmpty, loader} from '../../../js-helpers/helpers';
import {MyTable} from '../../../components/table/MyTable/MyTable';
import {useMyTable} from '../../../components/table/MyTable/useMyTable';
import {MyTableHead} from '../../../components/table/MyTable/MyTableHead';
import {MyTableBody} from '../../../components/table/MyTable/MyTableBody';
import {useFetchStates} from '../../../hooks/useFetchStates';
import {urlParams} from '../../../constants/urlParams';
import {useComboSearch} from '../../../hooks/url/useComboSearch';
import {useUpdateEffect} from '@react-hookz/web';
import {SearchInput} from '../../../components/form/SearchInput';
import {Link} from 'react-router-dom';
import {loggedAdmin} from '../../../stores/loggedAdmin';
import { EmptyDataWithoutSearch } from "../../../components/EmptyDataWithoutSearch/EmptyDataWithoutSearch";

const tableColls = [
	{
		head: 'ФИО администратора',
		cell: (admin) => {
			const text = getShortFullNameFromObj(admin)
			
			return loggedAdmin.isSuperAdmin
				?
				<Link to={`/admins/${admin.keycloak_id}/`}>
					{text}
				</Link>
				: text
		},
		nestedCellField: 'admin'
	}, {
		head: 'Дата и время генерации',
		cell: getLocaleFullDate,
		nestedCellField: 'date',
		cellClassName: 'py-2'
	}, {
		head: 'Статус',
		staticCell: 'Сгенерирован',
		col: {width: 150}
	},
]

const {SEARCH, SORT, PAGE, LIMIT} = urlParams

const searchParams = [
	{param: SEARCH, init: '', debounce: 333},
	{param: SORT, init: '-created_at'},
	{param: PAGE, init: 1, paramHandler: Number},
	{param: LIMIT},
]

export const QrLogs = ({idSetup}) => {
	const [
		[urlSearchQuery, setUrlSearchQuery],
		[urlSortField],
		[urlPage, setUrlPage],
		[urlLimit],
	] = useComboSearch(searchParams)
	
	const requestQuery = {
		page: urlPage,
		page_size: urlLimit,
		ordering: urlSortField,
		search: urlSearchQuery
	}
	
	const logsStates = useFetchStates(
		(_page = urlPage) => getSetupCodeLogs({...requestQuery, page: _page, setup: idSetup})
	)
	
	useEffect(() => {
		logsStates.getData()
	}, [urlPage, urlSortField])
	
	useUpdateEffect(() => {
		if (urlPage !== 1) {
			setUrlPage(1)
			return
		}
		logsStates.getData(1)
	}, [urlSearchQuery, urlLimit, urlSearchQuery])
	
	const {tableOptions, tableHeads, tableColgroup} = useMyTable(tableColls)
	
  const isEmptySearchQuery = isEmpty(urlSearchQuery);


	return (
		<div>
			<SearchInput
				resetWhen={!urlSearchQuery}
				withBorder
				className={'mb-1'}
				onChange={(e) => setUrlSearchQuery(e.target.value)}
				placeholder={'Поиск по администратору, серийному номеру, ID, keycloakID...'}
				defaultValue={urlSearchQuery}
			/>
			
      {
      isEmptySearchQuery && logsStates.isReady && logsStates.data?.count === 0 ? 
      
      <EmptyDataWithoutSearch/> : 

			<MyTable colgroup={tableColgroup}
			         showEmptyFoundDataMessage={isNotEmpty(urlSearchQuery)}
			         paginationAllCount={logsStates.data?.count}
			         isDataReady={logsStates.isReady}>
				
				<MyTableHead heads={tableHeads}/>
				
				<MyTableBody
					className={loader(logsStates.isReady)}
					data={logsStates.data?.results}
					highestRowToFill={45}
					tableOptions={tableOptions}/>
			</MyTable>
      }
		</div>
	)
}