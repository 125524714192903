import {toggle} from '../../js-helpers/helpers';
import useReRender from '../../hooks/useRerender';
import {useUpdateEffect} from '@react-hookz/web';
import {useRef} from 'react';

export const SearchInput = (
	{
		value,
		onChange,
		onKeyDown,
		resetWhen,
		withBorder,
		defaultValue,
		className = '',
		placeholder = '',
		classNameInput = '',
		...props
	}) => {
	
	const [key, rerender] = useReRender()
	const inputRef = useRef(null)
	
	useUpdateEffect(() => {
		const isFocused = inputRef.current === document.activeElement
		!isFocused && resetWhen
		&& rerender()
	}, [resetWhen])
	
	return (
		<fieldset
			className={`position-relative ${toggle(withBorder, 'px-1 py-75 rounded bg-transparent-60')} ${className}`}>
			<div className='position-relative has-icon-left'>
				
				<input
					key={key}
					value={value}
					ref={inputRef}
					type={'search'}
					onChange={onChange}
					onKeyDown={onKeyDown}
					placeholder={placeholder}
					defaultValue={defaultValue}
					className={`form-control ${classNameInput}`}
					{...props}/>
				
				<i className={'bx bx-search abs-c-left-center ml-75 text-muted'}/>
			</div>
		</fieldset>
	)
};