import axios from 'axios'
import {setPreparedAxiosParams} from '../services/axios/axiosFunctions';

// const base_url = 'http://192.168.31.249:8000'
// const base_url = process.env.REACT_APP_API_DOMAIN
// const base_url + '/api/v3/'

export function getSetupHistory() {
	return axios('devices/setup/',
		setPreparedAxiosParams({
			...arguments[0],
			// ever_have_device: true,
		}),
		// ...setAxiosApiVersion(3),


// params: {
// 	page,
// 	ordering,
// 	search,
// 	admin__account__keycloak_id: adminKeycloakID,
// 	created_at__gte: dateFrom,
// 	created_at__lte: dateTo
// },

// headers: {Authorization: `Bearer ${token}`}
// }
	);
}

export const getHistory = (id) => axios(`devices/setup/${id}/`)

export const patchSetupSerialNumber = (id, serial_number) =>
	axios.patch(`devices/setup/${id}/`, {serial_number})

export function getPhoneModels() {
	return axios('devices/setup/models/',
		setPreparedAxiosParams({...arguments[0]}),)
}

export const getSections = ({phone_model, instruction_version}) =>
	axios(`devices/setup/sections/`, {
		params: {phone_model, instruction_version},
		allowMultipleRequests: true
	})

export const getSection = (
	{
		sequence_number,
		phone_model,
		version_android,
		instruction_version
	}) =>
	axios(`devices/setup/sections/${sequence_number}/`, {
		params: {phone_model, version_android, instruction_version},
	})

export const createNewSetup = (phone) =>
	axios.post('devices/setup/', phone)
//
// export const completeSection = (data) =>
// 	axios.post('devices/setup/item/', data, {
// 	}) //!!!
//
// export const competedSetup = (data, token) =>
// 	axios.post('devices/setup/complete/', data, {
// 		skipErrorHandling: true
// 		// headers: {Authorization: `Bearer ${token}`}
// 	})//!!!
//
// export const getAdminInfo = (token) => axios('admins/current/', {
// 	// headers: {Authorization: `Bearer ${token}`}
// })
//
// export const getTestCode = (data, token) =>
// 	axios.post('devices/setup/codes/', data, {
// 		// headers: {Authorization: `Bearer ${token}`}
// 	})

export const getAdmins = (search) =>
	axios(`admins/`, {params: {search},})

// export const getSetupCodeLogs = (setup, page, ordering, search, token) =>
// 	axios.get('logs/device-setup-code/', {
// 		params: {setup, page, ordering, search},
// 		// headers: {Authorization: `Bearer ${token}`}
// 	})

export function getSetupCodeLogs() {
	return axios.get('logs/device-setup-code/',
		setPreparedAxiosParams({...arguments[0],}),
	)
}

// export const getSetupAutotestsLogs = (setup, page, ordering, search, token) =>
// 	axios('logs/device-autotests/', {
// 		params: {setup, page, ordering, search},
// 		// headers: {Authorization: `Bearer ${token}`}
// 	})

export function getSetupAutotestsLogs() {
	return axios('logs/device-autotests/',
		setPreparedAxiosParams({...arguments[0],}),)
}

export const getSetupAutotestItem = (id) => axios(`logs/device-autotests/${id}/`)

export const checkCache = (id) => axios(`devices/setup/${id}/cache/`)

export function getSetupDeletionHistory() {
	return axios(`devices/setup/deletion-history/`,
		setPreparedAxiosParams({...arguments[0],}),
	)
}

export const deleteSetup = (id) => axios.delete(`devices/setup/${id}/`)
