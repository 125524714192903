import SuccessSVG from './icons/success.svg'
import ErrorSVG from './icons/error.svg'
import './testing_style.css'
import {useParams} from "react-router-dom"
import React, {useEffect, useState} from "react"
import {getSetupAutotestItem} from "../../../../API/api"
import {useKeycloak} from "@react-keycloak/web"
import {Card} from '../../../../components/UI/Card';
import {ButtonGoBack} from '../../../../components/button/ButtonGoBack';
import {Head} from '../../../../components/UI/Head';
import {getLocaleFullDate} from '../../../../js-helpers/helpers';

const TEST_OPTIONS_NAMES = {
	root: 'Установка Root доступа',
	modules: 'Установка модулей',
	'efko phone': `Установка Efko phone как приложение для вызовов "по умолчанию"`,
	services: 'Запуск сервисов',
	cash: 'Отправка закэшированных данных'
}


export const SetupTesting = () => {
	const {keycloak} = useKeycloak()
	
	const {idSetup, idLog} = useParams()
	
	const [data, setData] = useState(null)
	const [isLoading, setIsLoading] = useState(false)
	
	useEffect(() => {
		setIsLoading(true)
		getSetupAutotestItem(idLog, keycloak.token)
			.then((response) => setData(response.data))
			.catch((error) => console.log(error))
			.finally(() => setIsLoading(false))
	}, [])
	
	return (
		<>
			{/*<Header/>*/}
			
			{/*<div className={'d-flex align-items-center justify-content-between mb-4'}>*/}
			{/*	<h2 className={'mb-0'}>Тестирование настройки*/}
			{/*		устройства {data?.date ? `${`(${new Date(data?.date).toLocaleString()})`}` : ''}</h2>*/}
			{/*	<BtnGoBack/>*/}
			{/*</div>*/}
			
			<ButtonGoBack className={'float-right'}/>
			<Head>
				Тестирование настройки устройства
				({getLocaleFullDate(data?.date)})
			</Head>
			
			<Card size={2}>
				{
					data?.sections.map((section) => (
						<Card secondary
						      size={0}
						      className={'mb-1'}
							// style={{backgroundColor: '#F4F7FC'}}
							    key={section.name}
						>
							<details>
								<summary className={'p-1'}>
									<div className={'d-flex justify-content-between align-items-center'}>
										<span className={'font-weight-bold'}>
											{TEST_OPTIONS_NAMES[section.name]}
										</span>
										
										{section?.items?.length > 1 &&
										<div className="summary-chevron-up m-1">
											<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
											     stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"
											     className="feather feather-chevron-down">
												<polyline points="6 9 12 15 18 9"/>
											</svg>
										</div>
										}
										
										<img alt={section.is_success ? 'Успешно' : 'Не успешно'}
										     src={section.is_success ? SuccessSVG : ErrorSVG}
										/>
									</div>
								</summary>
								
								<div className={`summary-content  ${section?.items?.length > 1 ? 'mt-1 pb-1 px-1' : ''}`}>
									
									{section?.items?.length > 1 &&
									section?.items.map((item) => (
										<div key={item.name}
										     className={'text-gray-500 d-flex justify-content-between py-25'}
										>
											<div>{item.name}</div>
											<img alt={item.is_success ? 'Успешно' : 'Не успешно'}
											     src={item.is_success ? SuccessSVG : ErrorSVG}
											/>
										</div>
									))}
								</div>
								
								{section?.items?.length > 1 &&
								<div className="summary-chevron-down m-1">
									<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
									     stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"
									     className="feather feather-chevron-up">
										<polyline points="18 15 12 9 6 15"/>
									</svg>
								</div>}
							</details>
						</Card>
					))
				}
			</Card>
		
		</>
	)
}