import React, {useEffect, useMemo, useRef} from 'react';
import {deviceAPI} from '../../../API/deviceAPI';
import Downshift from 'downshift';
import {MySelectContainer} from './components/MySelectContainer';
import {MySelectToggleButton} from './components/MySelectToggleButton';
import {MySelectList} from './components/MySelectList';
import {SearchInput} from '../SearchInput';
import {MySelectItem} from './components/MySelectItem';
import {InView} from 'react-intersection-observer';
import {
	getFullLauncherVersion,
	getFullNameFromObj,
	isEmpty,
	isString,
	loader,
	toggle,
} from '../../../js-helpers/helpers';
import {userAPI} from '../../../API/userAPI';
import {useNewFind} from '../../../hooks/useNewFind';
import {contactsAPI} from '../../../API/contactsAPI';
import {oneOf} from 'prop-types';
import {divisionsAPI} from '../../../API/divisionsAPI';
import {useFirstMountState, useUpdateEffect} from '@react-hookz/web';
import {adminAPI} from '../../../API/adminAPI';
import {getPhoneModels} from '../../../API/api';

const prepareDevices = (items) =>
	items.map(({brand_and_model, android_id}) => ({
		text: brand_and_model,
		extraText: `(id:${android_id})`,
		value: android_id,
	}));

const prepareDevicesSerialNumber = (items) =>
	items.map(({serial_number}) => ({
		text: serial_number,
		value: serial_number,
	}));


const prepareUsers = (items) =>
	items.map(({account}) => ({
		text: `${getFullNameFromObj(account)}`,
		extraText: `(id:${account.id})`,
		value: account.id,
	}));

const prepareUsersWithKeycloakID = (items) =>
	items.map(({account}) => ({
		text: `${getFullNameFromObj(account)}`,
		// extraText: `(ID: ${account.keycloak_id?.slice(0, 4) + '...'})`,
		value: account.keycloak_id,
	}));

const prepareItems = (items) =>
	items.map(({name, id}) => ({text: name, value: id}));

const prepareFullDataRules = (items) =>
	items.map((item) => ({text: item.name, value: item}));

const prepareDivisions = (items) =>
	items.map(({name}) => ({text: name, value: name}));

const prepareSubdivisions = (items) =>
	items.map((name) => ({text: name, value: name}));

const prepareVersions = (items) =>
	items.map(({version_code, version_name}) => ({
		text: getFullLauncherVersion(version_name, version_code),
		value: version_name,
	}))

const prepareAdmins = (items) =>
	items.map(({account}) => ({
		text: `${getFullNameFromObj(account)}`,
		value: account.keycloak_id,
	}))

const prepareSetupDevices = (items) =>
	items.map((item) => {
		const versionsOptions =
			item.version_android
				.map(({title, id}) => ({label: title, value: id}))
		
		return ({
			text: item.title,
			value: {modelID: item.id, versionsOptions},
		});
	})

// const modelsOptions = response.data.results.map(
// 				({title, id, version_android}) => {
//
// 					const versionsOptions = version_android
// 						.map(({title, id}) => ({label: title, value: id}))
//
// 					return {label: title, value: {modelID: id, versionsOptions}}
// 				})
// 			setDeviceOptions(modelsOptions)

const selectOptionsOnType = {
	devises: {
		apiMethod: deviceAPI.getAllFreeDevices,
		prepareItems: prepareDevices,
		findHolder: 'Поиск по свободным устройствам...',
	},
	allDevices: {
		apiMethod: deviceAPI.getDevices,
		prepareItems: prepareDevicesSerialNumber,
		findHolder: 'Поиск по устройствам...',
	},
	usersWithoutDevice: {
		apiMethod: userAPI.getAllWithoutDevice,
		prepareItems: prepareUsers,
		findHolder: 'Поиск по Пользователям без устройства...',
	},
	usersWithCalls: {
		apiMethod: userAPI.getAllWithCalls,
		prepareItems: prepareUsers,
		findHolder: 'Поиск по Пользователям со звонками...',
	},
	users: {
		apiMethod: userAPI.getAllUsers,
		prepareItems: prepareUsers,
		findHolder: 'Поиск по Пользователям...',
	},
	usersWithKeycloakID: {
		apiMethod: userAPI.getAllUsers,
		prepareItems: prepareUsersWithKeycloakID,
		findHolder: 'Поиск по Пользователям...',
	},
	rules: {
		apiMethod: contactsAPI.getAllRules,
		prepareItems: prepareItems,
		findHolder: 'Поиск по Правилам...',
	},
	rulesWithFullData: {
		apiMethod: contactsAPI.getAllRules,
		prepareItems: prepareFullDataRules,
		findHolder: 'Поиск по Правилам...',
	},
	subdivisions: {
		apiMethod: userAPI.getSubdivisions,
		prepareItems: prepareSubdivisions,
		findHolder: 'Поиск по Подразделениям...',
	},
	divisions: {
		apiMethod: divisionsAPI.getAll,
		prepareItems: prepareDivisions,
		findHolder: 'Поиск по Дивизионам...',
	},
	launcherVersions: {
		apiMethod: userAPI.getLauncherVersions,
		prepareItems: prepareVersions,
		findHolder: 'Поиск по Версиям прошивки...',
	},
	admins: {
		apiMethod: adminAPI.searchAdmins,
		prepareItems: prepareAdmins,
		findHolder: 'Поиск по Администраторам...'
	},
	setupDevices: {
		apiMethod: params => getPhoneModels({...params, page_size: 100}),
		prepareItems: prepareSetupDevices,
		findHolder: 'Поиск...'
	}
};

//TODO: добавить FilterSelect с useDownshift вместо Downshift
// внутри должна быть связаная логика с квери урла, нужен ресет, сет другого айтема извне

export const MySearchSelect = ({
	                               type,
	                               onSelect,
	                               // onChange,
	                               disabled,
	                               initText,
	                               resetDeps = [],
	                               resetWhen,
	                               init = true,
	                               noSearchInput,
	                               className = '',
	                               // inputProps = {},
	                               titleClassName = '',
	                               initialSelectedItem,
	                               defaultIsOpen = false,
	                               activeItemClassName = 'active',
                               }) => {
	const {apiMethod, prepareItems, findHolder} = selectOptionsOnType[type];
	
	// const resetComponentRef = useRef(() => {})
	//
	// useCustomCompareEffect(() => {
	// 	resetComponentRef.current()
	//
	// })
	
	const isFirstMount = useFirstMountState();
	const downshiftActionsRef = useRef(null);
	
	useEffect(() => {
		downshiftActionsRef.current?.selectItem(initialSelectedItem);
	}, [initialSelectedItem]);
	
	useUpdateEffect(() => {
		downshiftActionsRef.current?.clearSelection();
	}, resetDeps);
	
	useUpdateEffect(() => {
		resetWhen && downshiftActionsRef.current?.clearSelection();
	}, [resetWhen]);
	
	const {
		lastSearchPage,
		searchQuery,
		setSearchQuery,
		searchPage,
		setSearchPage,
		searchItemsStates,
	} = useNewFind(apiMethod);
	
	useEffect(() => {
		setSearchPage(1);
		searchItemsStates.getData({page: 1, search: searchQuery, page_size: 25});
	}, [searchQuery]);
	
	const onLastItemVisible = (inView) => {
		inView &&
		searchItemsStates.getData({
			page: searchPage,
			search: searchQuery,
			page_size: 25,
		});
	};
	
	const onChange = (selectItem) => {
		if (!selectItem || !onSelect) return;
		onSelect(selectItem);
	};
	
	const preparedItems = useMemo(
		() => prepareItems(searchItemsStates.data),
		[searchItemsStates.data]
	);
	
	if (!init)
		return (
			<input
				className={`form-control px-75 ${className}`}
				disabled={true}
				defaultValue={initText}
			/>
		);
	
	const showNoResults = isEmpty(preparedItems) && searchItemsStates.isReady;
	const preventLoadNextPage =
		!searchItemsStates.isReady || lastSearchPage || searchItemsStates.error;
	
	return (
		<Downshift
			onSelect={onChange}
			defaultIsOpen={defaultIsOpen}
			initialSelectedItem={initialSelectedItem}
			itemToString={(item) => item?.text}
		>
			{({
				  isOpen,
				  getMenuProps,
				  getItemProps,
				  getRootProps,
				  selectedItem,
				  getToggleButtonProps,
				  ...actions
			  }) => {
				if (isFirstMount) downshiftActionsRef.current = actions;
				
				return (
					<MySelectContainer
						containerProps={{
							getRootProps: getRootProps({}, {suppressRefError: true}),
							className,
							isOpen,
						}}
					>
						<MySelectToggleButton
							buttonProps={{
								disabled,
								titleClassName,
								getToggleButtonProps,
								isOpen,
							}}
						>
							{selectedItem?.text || initText}
							
							{selectedItem?.extraText && (
								<span className={'ml-auto'}>{selectedItem.extraText}</span>
							)}
						</MySelectToggleButton>
						
						<MySelectList getMenuProps={getMenuProps}>
							{!noSearchInput && (
								<SearchInput
									placeholder={findHolder}
									classNameInput={'text-c-crop'}
									onChange={(e) => setSearchQuery(e.target.value)}
									defaultValue={searchQuery}
								/>
							)}
							
							<ul
								className={`list-unstyled scroll-c overflow-y-auto height-350 ${toggle(
									isOpen,
									loader(searchItemsStates.isReady)
								)}`}
							>
								{showNoResults && (
									<li className={'dropdown-item disabled px-1 text-c-transform-none'}>
										{searchItemsStates.error
											? 'Ошибка загрузки!'
											: 'Не найдено...'}
									</li>
								)}
								
								{preparedItems.map((item, index) => {
									const {text, extraText, value} = item;
									const activeItem = selectedItem?.value === value;
									const key = isString(value) ? value : index;
									
									return (
										<MySelectItem
											key={key}
											itemProps={{
												activeItem,
												activeItemClassName,
												getItemProps: getItemProps({item, index}),
											}}
										>
											{text}
											
											{extraText && (
												<span className={'ml-auto'}>{extraText}</span>
											)}
										</MySelectItem>
									);
								})}
								
								<InView
									as={'li'}
									delay={333}
									className={`py-25 ${loader(searchItemsStates.isReady, {
										size: 'small',
									})}`}
									skip={preventLoadNextPage}
									onChange={onLastItemVisible}
								/>
							</ul>
						</MySelectList>
					</MySelectContainer>
				);
			}}
		</Downshift>
	);
};

MySearchSelect.propTypes = {
	type: oneOf([
		'devises',
		'allDevices',
		'users',
		'usersWithKeycloakID',
		'rules',
		'rulesWithFullData',
		'usersWithoutDevice',
		'usersWithCalls',
		'subdivisions',
		'divisions',
		'launcherVersions',
		'admins',
		'setupDevices'
	]).isRequired,
};
