import {setupAdminPermissions} from "../../constants/smassAdminPermissions"
import {ReactComponent as IconAdmin} from './icons/admin.svg'
import {ReactComponent as IconSetups} from './icons/mechamism.svg'
import {ReactComponent as IconDelHistory} from './icons/deletion_hist.svg'

const {
	LOGS, ADMINS, SETUPS, DELETION_HISTORY
} = setupAdminPermissions

export const navMenuItems = [
	{
		head: 'Администраторы',
		icon: <IconAdmin/>,
		description: 'Список администраторов',
		link: '/admins/',
		permission: ADMINS
	}, {
		head: 'Настройка устройства',
		icon: <IconSetups/>,
		description: 'Настройка устройств и просмотр истории настройки устройств',
		link: '/history/',
		permission: SETUPS,
	},
  {
		head: 'История удалений',
		icon: <IconDelHistory/>,
		description: 'Просмотр удаленных настроек',
		link: '/deletion_history/',
		permission: DELETION_HISTORY,
	},
	// {
	// 	head: 'Инструкции',
	// 	icon: <IconInstructions/>,
	// 	description: 'Просмотр файлов инструкций, в т.ч. истории версий',
	// 	link: '/instructions/',
	// 	permission: DEVICES
	// },
]

// export const tablesMenuItems = [
// 	{
// 		head: 'История звонков',
// 		icon: <IconCalls/>,
// 		description: 'Таблица звонков',
// 		link: 'calls/',
// 		permission: CALLS
// 	}, {
// 		head: 'Логи',
// 		icon: <Logs/>,
// 		description: 'Логи приложений, авторизации, обновления, отключения телефона и Crashlytics',
// 		link: 'logs/',
// 		// permission: LOGS
// 	}, {
// 		head: 'Скриншоты',
// 		icon: <Screenshots/>,
// 		description: 'Скриншоты устройств и пользователей',
// 		link: 'media/',
// 		// permission: LOGS
// 	},
// {
// 	head: 'Crashlytics',
// 	icon: <IconСrashlytics/>,
// 	description: 'История крашей',
// 	link: 'media/',
// 	permission: CRASHLYTICS
// },

// ]
//
// export const managementMenuItems = [
// 	{
// 		head: 'Дивизионы',
// 		icon: <IconDivision/>,
// 		description: 'Список дивизионов, настройка списка доступных приложений',
// 		link: 'divisions/',
// 		permission: DIVISIONS
// 	}, {
// 		head: 'Контакты',
// 		icon: <IconContacts/>,
// 		description: 'Список контактов, видимость контакта',
// 		link: 'internal-contacts/',
// 		permission: CONTACTS
// 	}, {
// 		head: 'Приложения',
// 		icon: <IconApps/>,
// 		description: 'Список приложений, список приложений по умолчанию',
// 		link: 'apps/',
// 		permission: APPS
// 	}, {
// 		head: 'Запросы от пользователей',
// 		icon: <IconFeedbacks/>,
// 		description: 'Обратная связь с пользователем',
// 		link: 'feedbacks/',
// 		permission: FEEDBACKS
// 	}, {
// 		head: 'Рассылка',
// 		icon: <IconSms/>,
// 		description: 'Рассылка событий на электронную почту',
// 		link: 'mailing/',
// 		permission: MAILINGS
// 	}, {
// 		head: 'Уведомления',
// 		icon: <IconNotification/>,
// 		description: 'Рассылка уведомлений на мобильный телефон пользователей',
// 		link: 'notifications/',
// 		permission: NOTIFICATIONS
// 	},
// ]