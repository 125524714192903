import React, {useEffect, useMemo, useState} from 'react'
import {getDateNow, getFileNameFormatDate, isEmpty, loader} from '../../js-helpers/helpers';
import {loggedAdmin} from '../../stores/loggedAdmin';
import {useUpdateEffect} from '@react-hookz/web';
import {columnsDeviceSetupHistory} from '../../components/table/MyTable/options/columnsDeviceSetupHistory';
import {useComboSearch} from '../../hooks/url/useComboSearch';
import {urlParams} from '../../constants/urlParams';
import {useMyTable} from '../../components/table/MyTable/useMyTable';
import {MyTable} from '../../components/table/MyTable/MyTable';
import {getCell, MyTableBody} from '../../components/table/MyTable/MyTableBody';
import {MyTableHead} from '../../components/table/MyTable/MyTableHead';
import {useFetchStates} from '../../hooks/useFetchStates';
import {getSetupHistory} from '../../API/api';
import {Head} from '../../components/UI/Head';
import {useExportData} from '../../services/export/useExportData';
import exportFromJSON from 'export-from-json';
import {toast} from 'react-toastify';
import {FiltersHistoryPage} from './FiltersHistoryPage';
import {BtnGoBack} from './setupHistory/BtnGoBack';
import {observer} from 'mobx-react-lite';
import {PortalModalDialog} from '../../components/modals/PortalModalDialog';
import {closeMyModal, showMyModal} from '../../components/MyModal/showModal';
import {CreateSetupModal} from './modal/CreateSetupModal';
import {useNavigate} from 'react-router-dom';

const {
	SEARCH, SORT, PAGE, LIMIT, EXPORT_MODE, ADMIN, DATE_FROM, DATE_TO, DEVICE
} = urlParams

const searchParams = [
	{param: SEARCH, init: '', debounce: 333},
	{param: SORT, init: '-created_at'},
	{param: PAGE, init: 1, paramHandler: Number},
	{param: LIMIT},
	{param: EXPORT_MODE},
	{param: ADMIN},
  {param: DEVICE},
	{param: DATE_FROM},
	{param: DATE_TO},
]

export const exportExcel = (exportList) => {
	const dataToExcel = exportList.map(item => {
		const finalObject = {}
		
		columnsDeviceSetupHistory.exportTable.forEach((col) => {
			finalObject[col.head] = getCell(col, item)
		})
		
		return finalObject
	})
	
	const fileName = `Настройки_${getFileNameFormatDate(getDateNow())}`
	
	exportFromJSON({
		data: dataToExcel,
		withBOM: true,
		fileName,
		exportType: exportFromJSON.types.xls,
	})
	
	toast.success(`Файл создан!
	Экспортировано настроек: ${dataToExcel.length}`)
}

export const SetupsHistoryPage = observer(() => {
	const [updateHistoryToggler, setUpdateHistoryToggler] = useState(false)
	
	const [
		[urlSearchQuery],
		[urlSortField],
		[urlPage, setUrlPage],
		[urlLimit],
		[urlIsExportMode],
		[urlAdmin],
    [urlDevice],
		[urlDateFrom],
		[urlDateTo],
	] = useComboSearch(searchParams)
	
	const exportRequestQuery = {
		search: urlSearchQuery,
		admin__account__keycloak_id: urlAdmin,
    serial_number: urlDevice,
		created_at__gte: urlDateFrom,
		created_at__lte: urlDateTo
	}
	
	const requestQuery = {
		page: urlPage,
		page_size: urlLimit,
		ordering: urlSortField,
		...exportRequestQuery
	}
	
	const setupsStates = useFetchStates(
		(_page = urlPage) => getSetupHistory({...requestQuery, page: _page,}),
		{noInitFetch: true, initData: {results: []}}
	)
	
	useEffect(() => {
		//если удаляем последний элемент таблицы и не хотил словить 404
		const needChangePage = setupsStates.data.results.length === 1 && urlPage > 1
		if (needChangePage) {
			setUrlPage(urlPage - 1)
			return
		}
		setupsStates.getData()
	}, [urlPage, urlSortField, updateHistoryToggler])
	
	useUpdateEffect(() => {
		if (urlPage !== 1) {
			setUrlPage(1)
			return
		}
		setupsStates.getData(1)
	}, [urlSearchQuery, urlLimit, urlAdmin, urlDateFrom, urlDateTo, urlDevice])
	
	const {isSuperAdmin} = loggedAdmin
	
	const {
		ButtonToggleExport,
		CounterToExport,
		ButtonSaveExport,
		checkBoxesColl,
	} = useExportData({
		requestMethod: getSetupHistory,
		requestParams: exportRequestQuery,
		pageData: setupsStates.data.results,
		onSaveExport: exportExcel
	})
	
	const isEmptySearchQuery = isEmpty(urlSearchQuery)
	
	const tableOptions = useMemo(() => {
		const options = columnsDeviceSetupHistory.getTableOptions(
			{isEmptySearchQuery, isSuperAdmin, setUpdateHistoryToggler}
		)
		
		if (urlIsExportMode)
			options.unshift(checkBoxesColl)
		
		return options
	}, [isEmptySearchQuery, urlIsExportMode])
	
	const {tableHeads, tableColgroup} = useMyTable(tableOptions)
	
	const navigate = useNavigate()
	
	function onClickCreateSetup() {
		showMyModal({
			RenderModal:
				<CreateSetupModal
					onCreate={response => {
						navigate(`/device_setup/${response.data.id}`)
						closeMyModal()
					}}
				/>
		})
	}
	
	return (
		<>
			{!urlIsExportMode && <BtnGoBack className={'float-right ml-1'}/>}
			
			<ButtonToggleExport className={'float-right'}/>
			
			<button onClick={onClickCreateSetup}
			        className={'btn btn-primary float-right mr-1'}>
				Настроить устройство
			</button>
			
			<Head title={'История настройки устройств'}/>
			
			<FiltersHistoryPage/>
			
			<CounterToExport/>
			
			<MyTable
				colgroup={tableColgroup}
				renderCardContainer={urlIsExportMode}
				appendElement={<ButtonSaveExport/>}
				showEmptyFoundDataMessage={setupsStates.data?.count === 0 && setupsStates.isReady}
				isDataReady={setupsStates.isReady}
				paginationAllCount={setupsStates.data?.count}>
				
				<MyTableHead heads={tableHeads}/>
				
				<MyTableBody className={loader(setupsStates.isReady)}
				             data={setupsStates.data?.results}
				             tableOptions={tableOptions}
				             highestRowToFill={45}
				/>
			</MyTable>
			
			{/*<PortalModalDialog/>*/}
		</>
	)
})