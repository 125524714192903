import { Link } from "react-router-dom";
import { getLocaleFullDate, getShortFullNameFromObj } from "../../../../js-helpers/helpers";

export const deletionHistoryTableOptions = [
	{
		head: 'ФИО администратора', 
    cell: ({admin_who_created}) =>//{console.log(data)},
    <Link className={'nav-link pl-0'} to={`/admins/${admin_who_created?.keycloak_id}/`}>
      {getShortFullNameFromObj(admin_who_created)}
    </Link>,
    col: {width: 200},
	}, {
		head: 'Устройство', 
    col: {width: 200},
		cell: ({phone_model}) => phone_model
	},
  {
    head: 'Версия системы',
    col: {width: 150},
    cell: ({version_android}) => version_android
  },
  {
    head: 'Серийный №',
    col: {width: 180},
    cell: ({serial_number}) => serial_number
  },
  {
    head: 'Дата и время настройки',
    col: {width: 170},
    cell: ({created_at}) => getLocaleFullDate(created_at),
    sortField: 'device_setup__created_at'
  },
  {
    head: 'Статус',
    col: {width: 150},
    cell: is_success => is_success ? 'Успешно' : 'Не завершено',
		nestedCellField: 'is_success',
  },
  {
    head: 'ФИО администратора, удалившего настройку',
    col: {width: 250},
    cell: ({admin_who_deleted}) =>  <Link className={'nav-link pl-0'} to={`/admins/${admin_who_deleted?.keycloak_id}/`}>
    {getShortFullNameFromObj(admin_who_deleted)}
  </Link>,
  },
  {
    head: 'Дата и время удаления',
    col: {width: 180},
    cell: ({deleted_at}) => getLocaleFullDate(deleted_at),
    sortField: 'device_setup__deleted_at'
  }
]