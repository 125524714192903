import { isFunction } from "../../js-helpers/helpers";

export const FilterPills = ({pillsOptions, search, onClosePill}) => {
  return (
    <ul className={'mb-1 list-unstyled'}>
    {pillsOptions.map(({ urlKey, label }) => {
      const value = search.get(urlKey);
      if (!value) return null;

      const finalLabel = isFunction(label) ? label(value) : label || value;

      return (
        <li key={urlKey} className={'chip mb-0 mr-1 bg-white'}>
          <div className={'chip-body'}>
            <span className="chip-text">{finalLabel}</span>

            <div
              className="chip-closeable"
              onClick={() => onClosePill(urlKey)}
            >
              <i className="bx bx-x" />
            </div>
          </div>
        </li>
      );
    })}
  </ul>
  )
}