export const urlParams = {
  ADMIN: 'admin',
  SEARCH: 'search',
  SORT: 'sort',
  PAGE: 'page',
  LIMIT: 'limit',
  USER: 'user',
  TYPE: 'type',
  SERVICE: 'service',
  DATE_FROM: 'from_date',
  DATE_TO: 'to_date',
  EXPORT_MODE: 'export',
  DIVISION: 'div',
  SUB_DIVISION: 'sub_div',
  LAUNCHER_VER: 'launcher_v',
  BLOCKED: 'blocked',
  HAVE_DEVICE: 'have_device',
  APP: 'app',
  STATUS: 'status',
  DEVICE: 'device',
  REASON: 'reason',
  DATE_OFF_FROM: 'date_off_from',
  DATE_OFF_TO: 'date_off_to',
  DATE_ON_FROM: 'date_on_from',
  DATE_ON_TO: 'date_on_to',
};
