import QRCode from "react-qr-code"
import {useParams} from 'react-router-dom';
import {useFetchStates} from '../../../../hooks/useFetchStates';
import {isNotEmpty, loader} from '../../../../js-helpers/helpers';
import classNames from 'classnames';
import {useToggle, useUpdateEffect} from '@react-hookz/web';
import {useTimeout} from '../../../../hooks/useTimeoutFn';
import dayjs from 'dayjs';
import {deviceAPI} from '../../../../API/deviceAPI';

// const getCode = () => new Promise(
// 	res => setTimeout(() => res({
// 		data: {
// 			"setup_id": 123,
// 			"code": "string",
// 			"expire_at": dayjs().add(2, 'second').toISOString()
// 		}
// 	}), 500)
// )

// StepQrCode
export default () => {
	const {setupID} = useParams()
	const {
		data: qrCodeData,
		isReady: isReadyData,
		getData: getQrCode,
		error
	} = useFetchStates(
		// getCode,
		() => deviceAPI.getQrTestCode(setupID),
		{noInitFetch: true, initReady: true}
	)

	const isCodeReady = isReadyData && isNotEmpty(qrCodeData)
	const [isCodeExpired, setIsCodeExpired] = useToggle(null)
	
	const [withDelaySetIsCodeExpired] = useTimeout(
		() => setIsCodeExpired(true),
		-dayjs().diff(dayjs(qrCodeData?.expire_at)))
	
	useUpdateEffect(() => {
		setIsCodeExpired(false)
		isCodeReady && withDelaySetIsCodeExpired()
	}, [qrCodeData])

	return (
		<div className={`d-flex align-items-center mt-1`}>
			
			<button className={'btn btn-primary btn-sm width-250'}
			        onClick={getQrCode}
			        disabled={!isReadyData || isCodeExpired === false}>
				Получить QR-код
			</button>
			
			<div className={classNames(
				loader(isReadyData, {size: 'small'}),
				{'bg-white': isCodeReady},
				'rounded mx-2 p-25'
			)}>
				{isNotEmpty(qrCodeData)
					?
					<QRCode size={100}
					        style={{
						        filter: isCodeExpired ? 'blur(2px)' : '',
						        shapeRendering: 'optimizeSpeed',
					        }}
					        value={JSON.stringify(qrCodeData)}
					        viewBox={'0 0 100 100'}
					/>
					: isNotEmpty(error) ?
            <>
              <i className="bx bx-x-circle text-danger mr-50 align-text-bottom"/>
              <span className='text-muted font-weight-bold font-small-2'>
                {error}
              </span>
            </> : '-'
				}
			</div>
			
			{isCodeExpired &&
			<>
				<i className="bx bx-time-five text-danger mr-50 align-text-bottom"/>
				<span className='text-muted font-weight-bold font-small-2'>
					Время действия QR-кода истекло. <br/>Запросите генерацию кода еще раз.
				</span>
			</>
			}
			
		
		
		</div>
	)
}