import { Link } from "react-router-dom";
import { Card } from "../../components/UI/Card";
import { Head } from "../../components/UI/Head";

function MenuAdminCard({menuItems, title}) {
  return (
    <Card className={'mb-1'}>
      <Head secondary>{title}</Head>
      <Card as={'ul'} size={0} className={'list-unstyled list-gap-05r'}>
				{menuItems.map(({link, icon, head, description}, index) =>
					(
						<li key={index}>
							<Link to={link}
							      className={'btn d-flex align-items-center bg-c-rgba-light hover-c-gray-75'}
							>
								<i className={'svg-curr-color mr-2'} style={{top: 0}}>{icon}</i>
								
								<div className={'w-100 text-left'}>
									<Head thirdly noMargins>{head}</Head>
									
									<span className={'font-small-3 text-muted'}>
										{description}
									</span>
								</div>
								
								<i className={'bx bx-chevron-right font-medium-5'} style={{top: 0}}/>
							</Link>
						</li>
					))}
        </Card>
      </Card>
  )
}

export default MenuAdminCard