import {CheckBox} from '../../../components/form/CheckBox';
import {Head} from '../../../components/UI/Head';
import {getLocaleFullDate, isNotEmpty} from '../../../js-helpers/helpers';
import './step.scss'
import StepQrCode from '../PageDeviceSetuping/StepQrCode';
import StepCacheCheck from '../PageDeviceSetuping/StepCacheCheck';
import classNames from 'classnames';

const getSettingDate = (arr = [], instructionStep) => {
	const preparedData = arr.filter((item) =>
		item.setting.sequence_number === instructionStep.sequence_number &&
		item.setting.subitem === 0
	)
	const date = preparedData[0]?.created_at
	return getLocaleFullDate(date)
}

export const DeviceSetupInfoStep = (
	{
		stepData,
		disabled,
		onCheck,
		stageSettings,
		stage,
		...props
	}) => {
	const {description, sequence_number, attachments} = stepData || {}
	const checked = stageSettings.some((item) => item.setting.id === stepData.id)
	const [isCheckQrCodeStep, isCheckServerCache] =
		stage !== 10 ? [false, false] : [sequence_number === 5, sequence_number === 7]
	
	return (
		<li className={'d-flex font-small-3'}>
			<div className={'step d-flex flex-column align-items-center bg-primary py-2 col-1'}>
				
				<Head as={'h5'}
				      noMargins
				      className={'text-white font-weight-bold mb-50'}>
					{`Шаг ${sequence_number}`}
				</Head>
				
				<CheckBox checked={checked}
				          disabled={disabled}
				          onChange={onCheck}
				          white/>
			</div>
			
			<div className={'w-100 px-2 pt-2 bg-c-gray-50'}>
				{description}
				
				{isNotEmpty(attachments) && <FilesList {...{attachments}}/>}
				{isCheckQrCodeStep && <StepQrCode/>}
				{isCheckServerCache && <StepCacheCheck/>}
				
				<br/>
				
				<p className={classNames(
					{'text-muted': checked, 'text-c-transparent': !checked},
					'font-small-2 mt-50'
				)
				}>
					{getSettingDate(stageSettings, stepData)}
				</p>
				
				{props.children}
			
			</div>
		</li>
	)
}

function FilesList({attachments}) {
	return attachments.map(({url, file, title}) =>
		<a key={url ?? file}
		   download={url ?? file}
		   href={url ?? file}
		   className={'ml-50 text-c-nowrap'}
		>
			{title}
		</a>
	)
}