import {adminAPI} from '../API/adminAPI';
import {makeAutoObservable} from 'mobx';
import {getLoggedAdminPermissions, smassAdminPermissions} from '../constants/smassAdminPermissions';

export const loggedAdmin = makeAutoObservable({
	data: {
		account: {
			first_name: '-',
			last_name: '-',
			login: '-',
		},
		available_sections: [],
		is_superadmin: false,
	},
	isReady: false,
	
	get isSuperAdmin() {
		return this.data.is_superadmin === true;
	},
	
	* fetchAdmin() {
		this.isReady = false
		this.error = null
		
		try {
			const {data} = yield adminAPI.getLoggedNow()
			this.data = data
			// this.data.is_superadmin = false
			this.data.available_sections = getLoggedAdminPermissions(data.is_superadmin)
			
		} finally {
			this.isReady = true
		}
	},
	
	getIsResolved(permission) {
		// console.log(this.data.available_sections);
		return this.data.is_superadmin
			? true
			: this.data.available_sections.some(p => p === permission)
	},
	
	// get isEmptyAdminMenu() {
	// 	return Object.entries(adminPermissions)
	// 	.filter(([key]) => !key.startsWith('USERS') && key !== 'GLOBAL_SEARCH')
	// 	.every(([, value]) => !this.getIsResolved(value))
	// }
	
}, {}, {autoBind: true})