import React from 'react';
import * as PropTypes from 'prop-types';
import {modal} from './newModal/Modal';

export function ModalDialog(
	{
		body,
		bodyClassName,
		className,
		footerProps,
		head,
		showFooter
	}) {
	return (
		<modal.Container className={className}>
			<modal.Head>{head}</modal.Head>
			<modal.Body className={bodyClassName}>{body}</modal.Body>
			{showFooter && <modal.Footer {...footerProps} />}
		</modal.Container>
	)
}

ModalDialog.propTypes = {
	footerProps: PropTypes.shape({
		onSuccessClick: PropTypes.any,
		cancelText: PropTypes.any,
		successText: PropTypes.any,
		onCancelClick: PropTypes.any,
		successDisabled: PropTypes.any,
	})
};
