// noinspection JSValidateTypes
/**
 * @description Hook to fetch data from an API and return the response, the loading state and the error state
 *
 * @param {Function} callback  - The callback function to fetch the data.
 * @param {Boolean} noReload  - If true, the hook will not reload the data when the component is re-rendered.
 * @param {Boolean} noInitFetch  - If true, the hook will not fetch the data when the component is mounted.
 * @param {Boolean} noSetData  - If true, the hook will not set the data/ready state.
 * @param {Object} initData  - The data to set in the state when the component is mounted.
 * @param {String} setNestedField  - The nested field to set in the state.
 * @param {Function} customSetter  - The custom setter function to set the data/ready in the state.
 
 * @return {{setReady: Dispatch<SetStateAction<unknown>>, data: Object, setData: Dispatch<SetStateAction<Object>>, setError: Dispatch<SetStateAction<unknown>>, isReady: unknown, error: unknown, getData: (function(...[any]): any)}}
 **/

import {useEffect} from 'react';
import {useSafeState} from '@react-hookz/web';

export function useFetchStates(
	callback, {
		noReload,
		noInitFetch,
		noSetData,
		initData,
		setNestedField,
		customSetter,
		initReady = false,
	} = {}) {
	
	const [data, setData] = useSafeState(initData)
	const [isReady, setReady] = useSafeState(initReady)
	const [error, setError] = useSafeState(null)
	
	useEffect(() => {
		!noInitFetch && getData()
	}, [])
	
	const getData = (...args) => {
		!noReload && setReady(false)
		setError(null)
		
		return callback(...args)
			.then(res => {
				if (customSetter)
					return customSetter(res, ...args)
				
				if (!noSetData) {
					const finalData = setNestedField
						? res.data[setNestedField]
						: res.data
					
					setData(() => {
						setReady(true)
						return finalData
					})
				}
				
				return res
			})
			.catch((err) => {
					const {response, request, message, config} = err || {}
					message !== 'canceled' && setReady(true)
					setError(response?.data?.errors?.[0]?.detail || message)
					return Promise.reject(err)
				}
			)
	}
	
	return {data, setData, isReady, setReady, error, setError, getData}
}