import React, {useMemo} from 'react';
import {useParams} from 'react-router-dom';
import {Head} from '../../../../components/UI/Head';
import {Card} from '../../../../components/UI/Card';
import {loader} from '../../../../js-helpers/helpers';
import {Columns} from '../../../../components/UI/Columns';
import {MyAvatar} from '../../../../components/UI/MyAvatar';
import {MyNewInput} from '../../../../components/form/MyNewInput';
import {useFetchStates} from '../../../../hooks/useFetchStates';
import {adminAPI} from '../../../../API/adminAPI';
import {BtnGoBack} from '../../../history/setupHistory/BtnGoBack';

const fields = {
	personal: [
		{label: 'Фамилия', name: 'last_name', disabled: true},
		{label: 'Имя', name: 'first_name', disabled: true},
		{label: 'Отчество', name: 'middle_name', disabled: true},
		{
			label: 'Дата Рождения',
			name: 'birth_date',
			type: 'date',
			readOnly: true,
		},
		// {label: 'Серия и номер паспорта', name: 'passport'},
		{label: 'Корпоративный № телефона', name: 'phone_numbers'},
		// {label: 'адрес прописки', name: 'register_address'},
		// {label: 'адрес проживания', name: 'residence_address'},
	],
	about: [
		{label: 'Дивизион', name: 'division'},
		{label: 'ПОДРАЗДЕЛЕНИЕ', name: 'subdivision'},
		{label: 'должность', name: 'position'},
		{label: 'адрес электронной почты', name: 'email'},
	],
	system: [
		{label: 'логин', name: 'login'},
		// {label: 'Пароль для входа в систему*', name: 'password'},
		// {label: 'повторите Пароль для входа в систему *', name: 'password2'},
	],
};

function CardPersonalInfo({data}) {
	const prepareInfo = useMemo(
		() =>
			data
				? fields.personal.map((field) => ({
					...field,
					value: data.account[field.name] || '',
				}))
				: [],
		[data]
	);
	
	return (
		<>
			<Head secondary noMargins className={'mb-1'}>
				Личная информация
			</Head>
			<Columns as={'ul'} sumItems={prepareInfo.length}>
				{prepareInfo?.map(({value, label}) => (
					<li key={label}>
						<MyNewInput value={value} label={label} disabled/>
					</li>
				))}
			</Columns>
		</>
	);
}

function CardAboutWorker({className = '', data}) {
	return (
		<Card title={'Информация о сотруднике'} secondary className={className}>
			<ul className={'list-unstyled list-gap-1r'}>
				{data?.map(({value, label}) => (
					<li key={label}>
						<MyNewInput value={value} label={label} disabled/>
					</li>
				))}
			</ul>
		</Card>
	);
}

function CardSystemInfo({className, data, selectElem}) {
	return (
		<Card title={'Системная информация'} secondary className={className}>
			{selectElem}
			<ul className={'list-unstyled list-gap-1r'}>
				{data?.map(({value, label}) => (
					<li key={label}>
						<MyNewInput value={value} label={label} disabled/>
					</li>
				))}
			</ul>
		</Card>
	);
}

export function PageAdminEdit() {
	const {keycloakID} = useParams()
	const {data, isReady} = useFetchStates(() => adminAPI.getInfo(keycloakID))
	
	const getFieldsValues = (field) => ({
		...field,
		value: data && data?.account[field.name] || '',
	})
	
	const [preparedAbout, preparedSystemInfo] = useMemo(
		() =>
			data
				? [fields.about.map(getFieldsValues), fields.system.map(getFieldsValues),]
				: [],
		[data]
	)
	
	return (
		<>
			<BtnGoBack className={'float-right'}/>
			<Head>Общая информация</Head>
			
			<Card as={'form'}>
				<Head secondary uppercase className="d-flex justify-content-between">
					<span>Просмотр администратора</span>
					<span className={'ml-auto text-warning text-uppercase font-small-3'}>
          {data && data.is_superadmin ? 'Cуперадминистратор' : ''}
        </span>
				</Head>
				
				<Card className={`mb-2 py-1 pl-1 pr-50 row m-0 ${loader(isReady)}`}
				      secondary
				      size={0}>
					
					<MyAvatar src={data?.account.profile_photo}
					          className={'mx-1'}
					          size={120}/>
					
					<div className={'col'}>
						<CardPersonalInfo data={data}/>
					</div>
				</Card>
				
				<section className={'row m-0 gap-1r'}>
					<CardAboutWorker className={`col ${loader(isReady)}`}
					                 data={preparedAbout}/>
					
					<CardSystemInfo className={`col ${loader(isReady)}`}
					                data={preparedSystemInfo}/>
				</section>
			</Card>
		</>
	)
}