import axios from 'axios';
import {setPreparedAxiosParams} from '../services/axios/axiosFunctions';


export const adminAPI = {
	getInfo: (keycloakID) => axios(`/admins/device-setup/${keycloakID}/`,),
	
	getAll: (page = 1) => axios('/admins/device-setup/', {...setPreparedAxiosParams({page}),}),
	
	searchAdmins({page, search, ordering, page_size}) {
		return axios('/admins/device-setup/', {...setPreparedAxiosParams(arguments[0]),})
	},
	
	getLoggedNow: () => axios('/admins/device-setup/current/',),
	patchAdmin: (keycloakID, data) => axios.patch(`/admins/device-setup/${keycloakID}/`, data,),
	
}

