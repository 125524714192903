import {useCallback, useRef} from 'react';
import {useUnmountEffect, useUpdateEffect} from '@react-hookz/web';

export function useTimeout(callback, delay, cancelDeps = []) {
	const timeoutRef = useRef(null)
	
	useUnmountEffect(() => { cancel() })
	
	useUpdateEffect(() => { cancel() }, cancelDeps)
	
	const cancel = () => { clearTimeout(timeoutRef.current) }
	
	const delayFn = useCallback(() => {
		timeoutRef.current = setTimeout(callback, delay)
	}, [delay])
	
	return [delayFn, cancel]
}