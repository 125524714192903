import axios from 'axios';
import {setPreparedAxiosParams} from '../services/axios/axiosFunctions';

export const divisionsAPI = {
	getAll({page_size = 25, ...params}) {
		return axios('/divisions/', setPreparedAxiosParams(arguments[0]));
	},
	
	// get: (id) => axios(`/divisions/${id}/`),
	
	getApps: (id) => axios(`/divisions/${id}/apps-settings/`),
	putApps: (id, data) =>
		axios.put(`/divisions/${id}/apps-settings/`, data),
	
	getDefaultApps: (page) => axios('/apps/default/', setPreparedAxiosParams({page})),
	postDefaultApps: (app_ids) => axios.post('/apps/default/', app_ids),
	
	getSites: (id) => axios(`/divisions/${id}/sites-settings/`),
	putSites: (id, data) => axios.put(`/divisions/${id}/sites-settings/`, data),
	
	getDefaultSites: (page) => axios('/sites/default/', setPreparedAxiosParams({page})),
	postDefaultSites: (site_ids) => axios.post('/sites/default/', site_ids),
}

