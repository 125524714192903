import {useInputValue} from '../../../../hooks/useInputValue';
import {useFetchStates} from '../../../../hooks/useFetchStates';
import {deviceAPI} from '../../../../API/deviceAPI';
import {toast} from 'react-toastify';
import {closeMyModal} from '../../../../components/MyModal/showModal';
import {ModalDialog} from '../../../../components/modals/ModalDialog';
import {isEmpty, loader} from '../../../../js-helpers/helpers';
import {Input} from '../../../../components/form/Input';

//ModalChangeSerialNumber
export default function({deviceSn, setupID, onSuccess}) {
	const {inputProps} = useInputValue({init: deviceSn})
	
	const patchSnStates = useFetchStates(
		() => deviceAPI.patchSetupSerialNumber(setupID, inputProps.value),
		{noInitFetch: true, initReady: true}
	)
	
	async function patchSN() {
		patchSnStates.getData()
			.then(() => {
				toast.success('Серийный номер изменен.')
				closeMyModal()
				onSuccess()
			})
	}
	
	return (
		<ModalDialog
			className={loader(patchSnStates.isReady)}
			head={'Изменение серийного номера'}
			body={
				<>
					<Input className={'w-100'}
					       autoFocus
					       errorMsg={patchSnStates.error && Object.values(patchSnStates.error.data)}
					       {...inputProps}
					       label={'Введите новый серийный номер'}/>
				</>
			}
			showFooter
			footerProps={{
				onCancelClick: closeMyModal,
				successText: 'Изменить',
				onSuccessClick: patchSN,
				successDisabled: deviceSn === inputProps.value || isEmpty(inputProps.value)
			}}
		/>
	)
}
