import {useParams} from 'react-router-dom'
import {useMemo} from 'react'
import {DeviceSetupInfoStep} from './stepReview/DeviceSetupInfoStep'
import {deviceAPI} from '../../API/deviceAPI'
import {DeviceSetupInfoStagesNav} from './stages/DeviceSetupInfoStagesNav'
import {useFetchStates} from '../../hooks/useFetchStates'
import {Card} from '../../components/UI/Card';
import {isEmpty, isNotEmpty, loader} from '../../js-helpers/helpers';
import {useUpdateEffect} from '@react-hookz/web';
import {useCustomSearch} from '../../hooks/url/useCustomSearch';
import classNames from 'classnames';
import {Head} from '../../components/UI/Head';
import {BtnGoBack} from '../history/setupHistory/BtnGoBack';
import {SetupDeviceTitle} from './SetupDeviceTitle';
import {DeviceSetupSubStepsList} from './stepReview/subStepReview/DeviceSetupSubStepsList';

export const PageDeviceSetupInfo = () => {
	const {setupID} = useParams()
	const [urlStage, setUrlStage] = useCustomSearch('stage', 1, {toNumber: true})
	
	const setupStates = useFetchStates(() => deviceAPI.getHistory(setupID))
	
	const allSectionsStates = useFetchStates(
		deviceAPI.getSections,
		{setNestedField: 'count', noInitFetch: true}
	)
	
	const currSectionStates = useFetchStates(
		(query) => deviceAPI.getSection(urlStage, query),
		{noInitFetch: true}
	)
	
	const [currSectionQuery, maxStageCompletedCount] = useMemo(() => {
		if (isEmpty(setupStates.data)) return [{}, 0]
		
		const {phone_model, version_android, settings, instruction} = setupStates.data
		const _currSectionQuery = {
			phone_model,
			version_android,
			instruction_version: instruction?.id
		}
		const _maxStageCompletedCount = Math.max(
			...settings.map((item) => item.setting.section.sequence_number)
		)
		
		return [_currSectionQuery, _maxStageCompletedCount]
	}, [setupStates.data])
	
	useUpdateEffect(() => {
		currSectionStates.getData(currSectionQuery)
	}, [urlStage])
	
	useUpdateEffect(async () => {
		await allSectionsStates.getData(currSectionQuery)
		currSectionStates.getData(currSectionQuery)
	}, [setupStates.data])
	
	const isReadyNav = allSectionsStates.isReady && maxStageCompletedCount
	const {phone_model_title, serial_number, version_android_title} = setupStates?.data || {}
	
	const getCurrSequenceTitle = () =>
		currSectionStates.data
			? `${currSectionStates.data?.sequence_number}. ${currSectionStates.data?.title}`
			: '-'
	
	return (
		<>
			<BtnGoBack className={'float-right ml-1'}/>
			<Head noMargins title={'История настройки'}/>
			
			<SetupDeviceTitle
				className={['py-1 rounded', loader(setupStates.isReady)]}
				{...{phone_model_title, serial_number, version_android_title}}
			/>
			
			<DeviceSetupInfoStagesNav
				currentStage={urlStage}
				className={loader(isReadyNav)}
				countStages={allSectionsStates.data}
				maxStageCompleted={maxStageCompletedCount}
				setCurrentStage={setUrlStage}/>
			
			<Card titleUppercase
			      title={getCurrSequenceTitle()}
			      className={`${loader(currSectionStates.isReady)}`}>
				
				<ul className={classNames(
					'list-unstyled overflow-hidden steps rounded-lg',
					{'height-500': isEmpty(currSectionStates.data)}
				)}>
					
					{currSectionStates.data?.setting?.map((step) => {
						const completedSetting = setupStates.data.settings
							.filter((item) => item.setting.section.sequence_number === urlStage)
						const isIncludedSubItems = isNotEmpty(step.subitem)
						
						const getCompletedSubSettings = () =>
							completedSetting.filter((item) =>
								item.setting.subitem !== 0 &&
								item.setting.sequence_number === step.sequence_number
							)
						
						return (
							<DeviceSetupInfoStep
								readOnly
								key={step.sequence_number}
								stepData={step}
								stageSettings={completedSetting}
							>
								{isIncludedSubItems &&
								<DeviceSetupSubStepsList
									subInstruction={step.subitem}
									readOnly
									completedSubSettings={getCompletedSubSettings()}
								/>
								}
							</DeviceSetupInfoStep>
						)
					})
					}
				</ul>
			</Card>
		</>
	)
}