import React from 'react';
import {useUpdateEffect} from '@react-hookz/web';
import useReRender from '../../hooks/useRerender';
import {isNotEmpty, toggle} from '../../js-helpers/helpers';
import PropTypes from 'prop-types';

export function Input(
	{
		label,
		className = '',
		disabled,
		defaultValue,
		errorMsg,
		...props
	}) {
	
	const [key, rerender] = useReRender()
	
	useUpdateEffect(() => {
		rerender()
	}, [defaultValue])
	
	const _inputProps = {disabled, defaultValue, ...props}
	
	if (label) {
		return (
			<label className={className} key={key}>
				{label}
				<input className={`form-control ${toggle(label, 'mt-50')}`} {..._inputProps} />
				{isNotEmpty(errorMsg) && <div className={'text-danger mt-50'}>{errorMsg}</div>}
			</label>
		)
	}
	
	return (
		<input key={key} className={`form-control ${className}`} {..._inputProps} />
	)
}

Input.propTypes = {
	onChange: PropTypes.any
}