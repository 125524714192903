import TEST from './icons/test.svg';
import LOGS from './icons/logs.svg';

const icons = {
  logs: LOGS,
  test: TEST
}

export const LogsIcon = ({type, className = ''}) => {
  // const Icon = icons[type];
  return <img src={icons[type]} alt='' className={className}/>
}