import React from 'react';
import classNames from 'classnames';

export const SetupDeviceTitle = (
	{
		className, phone_model_title, version_android_title, serial_number
	}) =>
	<h2 className={classNames(className)}>
		<span className={'text-primary'}>Устройство: </span>
		{phone_model_title}, {version_android_title}
		<span className={'px-1'}>|</span>
		<span className={'text-primary'}>Серийный №: </span>
		{serial_number}
	</h2>


