import {useFetchStates} from '../../../../hooks/useFetchStates';
import {deviceAPI} from '../../../../API/deviceAPI';
import {isNotEmpty, loader} from '../../../../js-helpers/helpers';
import {useParams} from 'react-router-dom';
import classNames from 'classnames';

const someEmptyCache = {
	false: {title: 'Кэш получен сервером', iconCname: 'bx-check-circle text-success'},
	true: {title: 'Кэша на сервере нет', iconCname: 'bx-x-circle text-danger'}
}

// StepCacheCheck
export default () => {
	const {setupID} = useParams()
	const cacheCheckStates = useFetchStates(
		() => deviceAPI.getDeviceCache(setupID),
		{noInitFetch: true, initReady: true}
	)
	
	return (
		<div className={'d-flex gap-2r align-items-center mt-1'}>
			<button className={'btn btn-primary btn-sm width-250'}
			        onClick={cacheCheckStates.getData}
			        disabled={!cacheCheckStates.isReady}
			>
				Проверить наличие кэша
			</button>
			
			<span className={classNames(
				loader(cacheCheckStates.isReady, {size: 'small'}),
				'rounded'
			)}
			>
				<CacheStatus {...cacheCheckStates}/>
			</span>
		</div>
	)
}

function CacheStatus({data, isReady}) {
	if (isReady && isNotEmpty(data)) {
		const isEmptySomeCache = Object.values(data).some(count => count === 0)
		const {title, iconCname} = someEmptyCache[isEmptySomeCache]
		
		return (
			<span className={'text-muted font-weight-bold '}>
				<i className={classNames('bx mr-50 align-bottom', iconCname)}/>
				{title}
			</span>
		)
	}
	
	return '-'
}