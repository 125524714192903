import {Link} from 'react-router-dom';
import {toggle} from '../../js-helpers/helpers';
import PropTypes from 'prop-types';
import React from 'react';

export function ButtonWithIcon(
	{
		type,
		href,
		fileName,
		noIcon,
		noText,
		to = '',
		children,
		className = '',
		disabled = false,
		...props
	}) {
	// if (type === 'person') {
	// 	return (
	// 		<Link to={'/users/new/'}
	// 		      className={`btn btn-outline-primary bg-white d-inline-flex align-items-center h-100 pl-75 pr-1 ${className}`}
	// 		      {...props}>
	// 			<svg width="1.5em" height="1.5em" className={'mr-50'}
	// 			     viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
	// 				<path fillRule="evenodd" clipRule="evenodd" fill="currentColor"
	// 				      d="M12 3.75a3.25 3.25 0 100 6.5 3.25 3.25 0 000-6.5zM7.25 7a4.75 4.75 0 119.5 0 4.75 4.75 0 01-9.5 0zm-2.609 8.641A4.75 4.75 0 018 14.25h8A4.75 4.75 0 0120.75 19v2a.75.75 0 01-1.5 0v-2A3.25 3.25 0 0016 15.75H8A3.25 3.25 0 004.75 19v2a.75.75 0 01-1.5 0v-2c0-1.26.5-2.468 1.391-3.359z"
	// 				/>
	// 			</svg>
	// 			{children}
	// 		</Link>
	// 	)
	// }
	
	if (type === 'info') {
		return (
			<Link to={to} className={`btn btn-link px-0 ${className}`}
			      {...props}>
				
				<svg width='1.5em' height='1.5em' viewBox='0 0 22 22' fill='none' xmlns='http://www.w3.org/2000/svg'>
					<path
						d='M10.5 0C4.696 0 0 4.697 0 10.5 0 16.304 4.697 21 10.5 21 16.304 21 21 16.303 21 10.5 21 4.696 16.303 0 10.5 0Zm0 19.36a8.854 8.854 0 0 1-8.86-8.86 8.854 8.854 0 0 1 8.86-8.86 8.854 8.854 0 0 1 8.86 8.86 8.854 8.854 0 0 1-8.86 8.86Z'
						fill='currentColor'/>
					<path
						d='M10.5 8.791a.82.82 0 0 0-.82.82v5.283a.82.82 0 1 0 1.64 0V9.61a.82.82 0 0 0-.82-.82Zm0-1.005a1.107 1.107 0 1 0 0-2.215 1.107 1.107 0 0 0 0 2.215Z'
						fill='currentColor'/>
				</svg>
				
				<span className={'ml-50'}>{children || 'Подробнее'}</span>
			</Link>
		)
	}
	
	if (type === 'block') {
		return (
			<Link to={to} className={`btn btn-link px-0 ${className}`}
			      {...props} >
				<svg width='1.5em' height='1.5em'
				     viewBox='0 0 21 21' fill='none'
				     xmlns='http://www.w3.org/2000/svg'>
					<path
						d='M10.5.875a9.625 9.625 0 1 0 9.625 9.625A9.636 9.636 0 0 0 10.5.875ZM2.625 10.5a7.83 7.83 0 0 1 1.728-4.91L15.41 16.647A7.865 7.865 0 0 1 2.625 10.5Zm14.022 4.91L5.59 4.353A7.865 7.865 0 0 1 16.647 15.41Z'
						fill='currentColor'/>
				</svg>
				<span className='ml-50'>{children}</span>
			</Link>
		)
	}
	
	if (type === 'save') {
		return (
			<button className={`btn ${
				toggle(disabled, 'disabled-c-btn ', 'btn-primary ')} ${className}`}
			        disabled={disabled}
			        type={'button'}
			        onClick={() => download(href, fileName)}
			        {...props}
			>
				{!noIcon &&
				<i className={`bx bx-download ${toggle(children, 'mr-75')} `}/>
				}
				
				{children}
			</button>
		)
	}

  if (type === 'save_call') {
		return (
			<button className={`btn ${
				toggle(disabled, 'disabled-c-btn ')} ${className}`}
			        disabled={disabled}
			        type={'button'}
			        onClick={() => download(href, fileName)}
			        {...props}
			>
				{!noIcon &&
				<i className={`bx bxs-download text-primary text-primary-custom font-medium-5 ${toggle(children, 'mr-75')} `}/>
				}
				
				{children}
			</button>
		)
	}
	
	if (type === 'export') {
		return (
			<button className={`btn btn-outline-primary pl-1 bg-white ${className}`}
			        {...props}
			        disabled={disabled}
			>
				<i className={'bx bx-upload mr-75'}/>
				Экспорт в Excel
			</button>
		)
	}
	
	async function download(href, fileName = '') {
		if (!href) return
		
		const res = await fetch(href, {method: 'GET'})
		const resBlob = await res.blob()
		
		const link = document.createElement('a')
		const url = window.URL.createObjectURL(new Blob([resBlob]))
		link.href = url

    const fileExtension = href.includes('.mp3') ? 'mp3' : href.includes('.mp4') ? 'mp4' : '';
		// const fileExtension = href.match(/(?<=\.).{2,5}(?=\?)/).at(0)
		link.setAttribute('download', `${fileName}.${fileExtension}`)

		document.body.appendChild(link)
		link.click()
		
		setTimeout(() => {
			link.parentNode.removeChild(link)
			window.URL.revokeObjectURL(url)
		})
	}
	
	return null
}

ButtonWithIcon.propTypes = {
	type: PropTypes.oneOf(['info', 'block', 'save', 'export', 'save_call']),
}