import {ReactComponent as LogsSVG} from '../../../../pages/history/setupHistory/icons/logs.svg'
import {ReactComponent as WatchSVG} from '../../../../pages/history/setupHistory/icons/watch.svg'
import {ReactComponent as ContinueSVG} from '../../../../pages/history/setupHistory/icons/continue.svg'
import {ReactComponent as DeleteSVG} from '../../../../pages/history/setupHistory/icons/delete.svg'

import {Link} from 'react-router-dom';
import {WithTooltip} from '../../../UI/WithTooltip';
import {getFullNameFromObj, getLocaleFullDate, getShortFullNameFromObj} from '../../../../js-helpers/helpers';
import {loggedAdmin} from '../../../../stores/loggedAdmin';
import {closeMyModal, showMyModal} from '../../../MyModal/showModal';
import {toast} from 'react-toastify';
import {ModalDialog} from '../../../modals/ModalDialog';
import {deleteSetup} from '../../../../API/api';


export const columnsDeviceSetupHistory = {
	
	getTableOptions: ({isEmptySearchQuery, isSuperAdmin, setUpdateHistoryToggler}) =>
		[{
			head: 'ФИО администратора',
			col: {width: 200},
			cell: (admin) => {
				const text = isEmptySearchQuery
					? getShortFullNameFromObj(admin)
					: getFullNameFromObj(admin)
				
				return loggedAdmin.isSuperAdmin
					?
					<Link to={`/admins/${admin.keycloak_id}/`}>
						{text}
					</Link>
					: text
			},
			nestedCellField: 'admin',
			sortField: 'admin'
		},
			...getBaseColls(isEmptySearchQuery),
			{
				head: 'Действия',
				cell: ({is_success, id, serial_number}) =>
					<HistoryRowActions {...{
						isSuperAdmin,
						is_success,
						id,
						serial_number,
						setUpdateHistoryToggler
					}}/>,
				col: {width: 100},
			},
		],
	
	exportTable: [{
		head: 'ФИО администратора', cell: getFullNameFromObj, nestedCellField: 'admin',
	},
		...getBaseColls()
	]
}

function getBaseColls() {
	return [{
		head: 'Устройство',
		cell: 'phone_model',
	}, {
		head: 'Версия системы',
		cell: 'version_android',
	}, {
		head: 'Серийный № ',
		cell: 'serial_number',
		cellClassName: 'word-break-all max-width-200'
	}, {
		head: 'Дата и время настройки',
		cell: getLocaleFullDate,
		nestedCellField: 'created_at',
		cellClassName: 'py-2',
		col: {width: 200},
		sortField: 'created_at',
	}, {
		head: 'Статус',
		cell: is_success => is_success ? 'Успешно' : 'Не завершено',
		nestedCellField: 'is_success',
		col: {width: 150},
	},];
}


function HistoryRowActions({isSuperAdmin, is_success, id, serial_number, setUpdateHistoryToggler}) {
	const deleteSetupItem = () => {
		deleteSetup(id)
			.then(() => {
				toast.success('Настройка удалена!')
				closeMyModal()
				setUpdateHistoryToggler(curr => !curr)
			})
			.catch(({response}) => {
				toast.error(response?.data?.errors?.[0]?.detail)
			})
	}
	
	const handleDeleteSetup = () => {
		showMyModal({
			RenderModal:
				<ModalDialog
					head={'Удаление настройки'}
					body={
						<div className="text-center">
							Вы действительно хотите удалить настройку устройства <b>{serial_number}</b>?
						</div>
					}
					showFooter
					footerProps={{
						successText: 'Удалить',
						onSuccessClick: deleteSetupItem,
						onCancelClick: closeMyModal,
					}}
				/>
		})
	}
	
	return (
		<div className='d-flex'>
			{isSuperAdmin &&
			<Link className={'mr-2 c-hover-opacity min-c-touch-zone'} to={`/history/${id}/logs/`}>
				<WithTooltip delay={1000}
				             elem={<LogsSVG/>}
				             tooltips={['Логи']}/>
			</Link>
			}
			
			<Link className={'c-hover-opacity min-c-touch-zone'}
			      to={is_success ? `/history/${id}/` : `/device_setup/${id}/`}>
				
				{is_success
					?
					<WithTooltip delay={1000}
					             elem={<WatchSVG/>}
					             tooltips={['Просмотреть']}/>
					:
					<WithTooltip delay={1000}
					             elem={<ContinueSVG/>}
					             tooltips={['Продолжить']}/>
				}
			</Link>
			
			{isSuperAdmin &&
			<button type={'button'}
			        className={'btn p-0 ml-2 c-hover-opacity min-c-touch-zone'}
			        onClick={handleDeleteSetup}
			>
				<WithTooltip delay={1000}
				             elem={<DeleteSVG/>}
				             tooltips={['Удалить']}/>
			</button>
			}
		</div>
	)
}
