import {useParams} from 'react-router-dom'
import {useMemo} from 'react'
import {DeviceSetupInfoStep} from '../stepReview/DeviceSetupInfoStep'
import {DeviceSetupInfoStagesNav} from '../stages/DeviceSetupInfoStagesNav'
import {Card} from '../../../components/UI/Card';
import {isEmpty, isLastItem, isNotEmpty, loader} from '../../../js-helpers/helpers';
import {useCustomSearch} from '../../../hooks/url/useCustomSearch';
import classNames from 'classnames';
import {Head} from '../../../components/UI/Head';
import {BtnGoBack} from '../../history/setupHistory/BtnGoBack';
import {SetupDeviceTitle} from '../SetupDeviceTitle';
import {DeviceSetupSubStepsList} from '../stepReview/subStepReview/DeviceSetupSubStepsList';
import useChangeStepsResolver from './hooks/useChangeStepsResolver'
import usePageRequests from './hooks/usePageRequests'
import useUnsavedSteps from './hooks/useUnsavedSteps'
import {showMyModal} from '../../../components/MyModal/showModal';
import ModalChangeSerialNumber from './ModalChangeSerialNumber'

export const PageDeviceSetuping = () => {
	const {setupID} = useParams()
	const [urlStage, setUrlStage] = useCustomSearch('stage', null, {toNumber: true})
	
	const {
		allSectionsCountStates,
		completedStagesCount,
		currSectionStates,
		setupStates,
		reFetchSetup
	} = usePageRequests({setupID, urlStage, setUrlStage})
	
	const {phone_model_title, serial_number, version_android_title} = setupStates?.data || {}
	
	const getCurrSequenceTitle = () =>
		currSectionStates.data
			? `${currSectionStates.data?.sequence_number}. ${currSectionStates.data?.title}`
			: '-'
	
	const {
		toggleChecked,
		unsavedSetups,
		onStageChange,
		isReadySaveSteps,
		isReadyFinishSetup,
		SetupFinishBtn
	} = useUnsavedSteps({
		setupID,
		urlStage,
		setUrlStage,
		currSectionData: currSectionStates.data,
		isSavedStage: urlStage <= completedStagesCount,
		reFetchSetup
	})
	
	const isReadyNav = allSectionsCountStates.isReady && isReadySaveSteps
	
	const completedSettings = useMemo(() => {
		const savedSettings = setupStates.data?.settings
			.filter((item) => item.setting.section.sequence_number === urlStage)
		return [...savedSettings || [], ...Object.values(unsavedSetups)]
	}, [setupStates.data, unsavedSetups, currSectionStates.data])
	
	const {
		getIsResolvedChange, changeResolvedIDS
	} = useChangeStepsResolver({
		currSectionData: currSectionStates.data,
		isResolveChangeStage: urlStage === completedStagesCount + 1,
		unsavedSetups
	})
	
	const onCheck = (e, step) => {
		if (!getIsResolvedChange(step.id)) return
		const {checked} = e.target
		toggleChecked({checked, step})
		changeResolvedIDS(checked, step.id)
	}
	
	const checkParentStep = (e, parentStep, subStepID) => {
		if (!getIsResolvedChange(subStepID)) return
		const {checked} = e.target
		toggleChecked({checked, step: parentStep})
	}
	
	function onChangeDeviceSN() {
		showMyModal({
			RenderModal:
				<ModalChangeSerialNumber
					deviceSn={serial_number}
					onSuccess={reFetchSetup}
					setupID={setupID}/>
		})
	}
	
	const isLastStage = urlStage === allSectionsCountStates.data
	const hideMainLoader =
		currSectionStates.isReady && isReadySaveSteps && isReadyFinishSetup
	
	return (
		<>
			<BtnGoBack className={'float-right ml-1'}/>
			<button className={'btn btn-primary float-right'}
			        disabled={!setupStates.isReady}
			        onClick={onChangeDeviceSN}
			>
				Изменить серийный №
			</button>
			<Head noMargins title={'Настройка устройства'}/>
			
			<SetupDeviceTitle
				className={['py-1 rounded', loader(setupStates.isReady)]}
				{...{phone_model_title, serial_number, version_android_title}}
			/>
			
			<DeviceSetupInfoStagesNav
				currentStage={urlStage}
				className={loader(isReadyNav)}
				countStages={allSectionsCountStates.data}
				maxStageCompleted={completedStagesCount}
				setCurrentStage={onStageChange}/>
			
			<Card titleUppercase
			      title={getCurrSequenceTitle()}
			      className={loader(hideMainLoader)}>
				
				<ul className={classNames(
					'list-unstyled overflow-hidden steps rounded-lg',
					{'height-500': isEmpty(currSectionStates.data)}
				)}>
					
					{currSectionStates.data?.setting?.map((step) => {
						const isIncludedSubItems = isNotEmpty(step.subitem)
						
						return (
							<DeviceSetupInfoStep
								stage={urlStage}
								key={step.sequence_number}
								stepData={step}
								disabled={isIncludedSubItems}
								onCheck={e => {onCheck(e, step)}}
								stageSettings={completedSettings}
							>
								{isIncludedSubItems &&
								<DeviceSetupSubStepsList
									subInstruction={step.subitem}
									onCheck={(e, instruction, ind) => {
										onCheck(e, instruction)
										isLastItem(ind, step.subitem.length) &&
										checkParentStep(e, step, instruction.id)
									}}
									completedSubSettings={
										completedSettings.filter(({setting}) =>
											setting.subitem !== 0 &&
											setting.sequence_number === step.sequence_number
										)}
								/>
								}
							</DeviceSetupInfoStep>
						)
					})}
				</ul>
				
				<button onClick={() => onStageChange(urlStage - 1)}
				        disabled={urlStage <= 1}
				        className={'btn btn-outline-primary'}>
					Назад
				</button>
				
				{isLastStage
					?
					<SetupFinishBtn/>
					:
					<button onClick={() => onStageChange(urlStage + 1)}
					        className={'btn btn-primary float-right'}>
						Далее
					</button>
				}
			</Card>
		</>
	)
}