import {useSearchParams} from 'react-router-dom';
import {useImmer} from 'use-immer';
import {getFullNameFromObj, getIsoUtcDate, getLocaleFullDate, toggle,} from '../../js-helpers/helpers';
import {useFetchStates} from '../../hooks/useFetchStates';
import {useMountEffect, useToggle, useUpdateEffect} from '@react-hookz/web';
import {SearchInput} from '../../components/form/SearchInput';
import {MySearchSelect} from '../../components/form/MySelect/MySearchSelect';
import {urlParams} from '../../constants/urlParams';
import {useComboSearch} from '../../hooks/url/useComboSearch';
import React from 'react';
import dayjs from 'dayjs';
import {RangeDatePicker} from '../../components/form/RangeDatePicker';
import {FilterPills} from '../../components/FilterPills/FilterPills';
import {ButtonFilter} from '../../components/button/ButtonFilter';
import {adminAPI} from '../../API/adminAPI';
import {observer} from 'mobx-react-lite';
import {loggedAdmin} from '../../stores/loggedAdmin';

const {SEARCH, DATE_FROM, DATE_TO, ADMIN, DEVICE} = urlParams;
const pageSearchParams = [
	{param: SEARCH, debounce: 333},
	{param: ADMIN},
  {param: DEVICE},
	{param: DATE_FROM},
	{param: DATE_TO},
];

export const FiltersHistoryPage = observer(() => {
	const [
		[urlSearchQuery, setUrlSearchQuery],
		[urlAdmin, setUrlAdmin],
    [urlDevice, setUrlDevice],
		[urlDateFrom, setUrlDateFrom],
		[urlDateTo, setUrlDateTo],
	] = useComboSearch(pageSearchParams)
	
	const [showFilters, toggleShowFilters] = useToggle(
		urlAdmin || urlDevice || urlDateFrom || urlDateTo
	)
	
	useUpdateEffect(() => {
		if (!showFilters) {
			setUrlAdmin('');
			setUrlDateFrom('');
			setUrlDateTo('');
      setUrlDevice('');
			setInitFilters((draft) => {
				draft.admin = {
					text: '',
					value: '',
				};
        draft.device = {
					text: '',
					value: '',
				};
			})
		}
	}, [showFilters]);
	
	const [search, setSearch] = useSearchParams();
	
	const [pillsOptions, setPillsOptions] = useImmer(() => [
		{
			urlKey: SEARCH,
			label: null,
		}, {
			urlKey: ADMIN,
			label: null,
		}, {
			urlKey: DEVICE,
			label: null,
		},{
			urlKey: DATE_FROM,
			label: (date) => `От  ${getLocaleFullDate(date)}`,
		}, {
			urlKey: DATE_TO,
			label: (date) => `До  ${getLocaleFullDate(date)}`,
		},
	]);
	
	const [initFilters, setInitFilters] = useImmer({
		admin: null,
    device: null,
	});
	
	const customSetter = ({data: {account}}) => {
		const text = `${getFullNameFromObj(account)}`;
		
		setPillsOptions((draft) => {
			draft.find((_pill) => _pill.urlKey === ADMIN).label = text;
		});
		
		setInitFilters((draft) => {
			draft.admin = {
				text,
				value: account.keycloak_id,
			};
		});
		initAdminState.setReady(true);
	};
	
	const initAdminState = useFetchStates(() => adminAPI.getInfo(urlAdmin), {
		noInitFetch: true,
		customSetter,
	});
	
	useMountEffect(() => {
		urlAdmin && initAdminState.getData();
    urlDevice && 	setInitFilters((draft) => {
			draft.device = {
				text: urlDevice,
				value: urlDevice,
			};
		});
	});
	
	const onAdminSelect = (selectedOption) => {
		if (!selectedOption) return;
		const {text, value} = selectedOption;
		
		setUrlAdmin(value);
		setPillsOptions((draft) => {
			draft.find((_pill) => _pill.urlKey === ADMIN).label = text;
		});
		initAdminState.setReady(true);
	};

  const onDeviceSelect = (selectedOption) => {
		if (!selectedOption) return;
		const {text, value} = selectedOption;
	
		setUrlDevice(value);
		setPillsOptions((draft) => {
			draft.find((_pill) => _pill.urlKey === DEVICE).label = text;
		});
	};
	
	const onClosePill = (urlKey) => {
		search.delete(urlKey);
		setSearch(search.toString(), {replace: true});
	};
	
	return (
		<>
			<div
				className={
					'row no-gutters text-muted gap-1r align-items-center mb-50 px-1 py-75 rounded bg-transparent-60'
				}
			>
				<div className={'col-12 row no-gutters align-items-center'}>
					<SearchInput
						resetWhen={!urlSearchQuery}
						className={'col mr-1'}
						onChange={(e) => setUrlSearchQuery(e.target.value)}
						placeholder={'Поиск по фамилии администратора и серийному номеру...'}
						defaultValue={urlSearchQuery}
					/>
					
					<ButtonFilter showFilters={showFilters} toggleShowFilters={toggleShowFilters}/>
				</div>
				
				{showFilters && (
					<div className={'row col-12 no-gutters justify-content-between align-items-center gap-1r'}>
						
						{loggedAdmin.isSuperAdmin
							?
								<MySearchSelect
									className={'col'}
									type={'admins'}
									// init={urlAdmin && initAdminState.isReady}
									initialSelectedItem={initFilters.admin}
									resetWhen={!urlAdmin}
									onSelect={onAdminSelect}
									initText={'Все администраторы'}
									titleClassName={`${toggle(!urlAdmin, 'text-muted')}`}
								/>
							:
							<></>
						}

            {/* <MySearchSelect
							className={'col'}
							type={'allDevices'}
							init={urlDevice}
							initialSelectedItem={initFilters.device}
							resetWhen={!urlDevice}
							onSelect={onDeviceSelect}
							initText={'Все устройства'}
							titleClassName={`${toggle(!urlDevice, 'text-muted')}`}
						/> */}
						
						<RangeDatePicker
							fromPickerProps={{
								value: urlDateFrom,
								onChange: ([date]) => {
									setUrlDateFrom(date && getIsoUtcDate(date));
								},
								options: {maxDate: urlDateTo},
							}}
							toPickerProps={{
								value: urlDateTo,
								onChange: ([date]) => {
									setUrlDateTo(
										date && getIsoUtcDate(dayjs(date).second(59).toDate())
									);
								},
								options: {minDate: urlDateFrom},
							}}
						/>
					</div>
				)}
			</div>
			
			<FilterPills pillsOptions={pillsOptions} search={search} onClosePill={onClosePill}/>
		</>
	);
})


// import {useUpdateEffect} from "@react-hookz/web";
// import dayjs from "dayjs";
// import {useEffect, useState} from "react";
// import {useImmer} from "use-immer";
// import {getLocaleFullDate, isFunction, toggle} from "../../old_src/js-helpers/helpers"
// import {RangeDatePicker} from "../../old_src/components/MySelect/RangeDatePicker/RangeDatePicker";
// import {InputSearch} from "./inputSearch/InputSearch"
// import MySearchSelect from "./MySearchSelect/MySearchSelect";
//
// const urlParams = {
// 	SEARCH: 'search',
// 	ADMIN: 'admin',
// 	DATE_FROM: 'date_from',
// 	DATE_TO: 'date_to'
// }
//
// const {ADMIN, DATE_FROM, DATE_TO, SEARCH} = urlParams;
//
// const FiltersHistoryPage = (
// 	{
// 		onChange,
// 		setSearchInput,
// 		searchInput,
// 		adminFilter,
// 		setAdminFilter,
// 		setDateFromFilter,
// 		setDateToFilter,
// 		dateFromFilter,
// 		dateToFilter,
// 		setAdminNameFilter,
// 		isSuperadmin
// 	}) => {
// 	const [showFilters, toggleShowFilters] = useState(false);
//
// 	const [pillsOptions, setPillsOptions] = useImmer(() => [
// 		{
// 			urlKey: SEARCH,
// 			label: searchInput,
// 		}, {
// 			urlKey: ADMIN,
// 			label: adminFilter,
// 		}, {
// 			urlKey: DATE_FROM,
// 			label: dateFromFilter,
// 			// label: (dateFromFilter) => `От  ${getLocaleFullDate(dateFromFilter)}`,
// 		}, {
// 			urlKey: DATE_TO,
// 			label: dateToFilter
// 			// label: (dateToFilter) => `До  ${getLocaleFullDate(dateToFilter)}`,
// 		},
// 	]);
//
// 	const onAdminSelect = (selectedOption) => {
// 		if (!selectedOption) return
// 		const {text, value} = selectedOption
//
// 		setPillsOptions((draft) => {
// 			draft.find((_pill) => _pill.urlKey === ADMIN).label = text;
// 		})
//
// 		setAdminFilter(value)
// 		setAdminNameFilter(text)
// 	}
//
// 	useEffect(() => {
// 		if (!showFilters) {
// 			setSearchInput('')
// 			setAdminFilter('')
// 			setDateFromFilter('')
// 			setDateToFilter('')
// 			setAdminNameFilter('')
// 			setPillsOptions((draft) => {
// 				draft.find((_pill) => _pill.urlKey === SEARCH).label = '';
// 			});
// 			setPillsOptions((draft) => {
// 				draft.find((_pill) => _pill.urlKey === DATE_FROM).label = '';
// 			});
// 			setPillsOptions((draft) => {
// 				draft.find((_pill) => _pill.urlKey === DATE_TO).label = '';
// 			});
// 			setPillsOptions((draft) => {
// 				draft.find((_pill) => _pill.urlKey === ADMIN).label = '';
// 			});
// 		}
// 	}, [showFilters])
//
// 	useUpdateEffect(() => {
// 		setPillsOptions((draft) => {
// 			draft.find((_pill) => _pill.urlKey === SEARCH).label = searchInput;
// 		});
// 	}, [searchInput])
//
// 	// useUpdateEffect(() => {
// 	//   !adminFilter && setPillsOptions((draft) => {
// 	//     draft.find((_pill) => _pill.urlKey === ADMIN).label = '';
// 	//   });
// 	// }, [adminFilter])
//
// 	useUpdateEffect(() => {
// 		setPillsOptions((draft) => {
// 			draft.find((_pill) => _pill.urlKey === DATE_FROM).label = dateFromFilter ? `От  ${getLocaleFullDate(dateFromFilter)}` : '';
// 		});
// 	}, [dateFromFilter])
//
// 	useUpdateEffect(() => {
// 		setPillsOptions((draft) => {
// 			draft.find((_pill) => _pill.urlKey === DATE_TO).label = dateToFilter ? `До  ${getLocaleFullDate(dayjs(dateToFilter).second(59).toDate())}` : '';
// 		});
// 	}, [dateToFilter])
//
// 	const onClosePill = (urlKey) => {
// 		if (urlKey === SEARCH) {
// 			setSearchInput('')
// 		} else if (urlKey === ADMIN) {
// 			setPillsOptions((draft) => {
// 				draft.find((_pill) => _pill.urlKey === ADMIN).label = '';
// 			});
// 			setAdminFilter('');
// 			setAdminNameFilter('')
// 		} else if (urlKey === DATE_FROM) {
// 			setDateFromFilter('')
// 		} else if (urlKey === DATE_TO) {
// 			setDateToFilter('')
// 		}
// 	};
//
//
// 	return (
// 		<>
// 			<div className={'no-gutters text-muted align-items-center field-filters'}>
// 				<div className={'d-flex'}>
// 					<InputSearch value={searchInput}
// 					             onChange={onChange}
// 					             className={'col-11 ps-0 me-1'}/>
// 					<button onClick={() => toggleShowFilters(!showFilters)}
// 					        className={`btn btn-icon btn-outline-primary d-flex align-items-center font-small-3 col-1 ${toggle(showFilters, '', 'border-c-transparent')}`}>
// 						<i className={'bx bx-menu-alt-left me-2 font-medium-1'}/>
// 						Фильтр
// 					</button>
// 				</div>
// 				{showFilters &&
// 				<div className={'mt-2 d-flex'}>
// 					{isSuperadmin &&
// 					<MySearchSelect type={'admins'}
// 					                initText={'Все администраторы'}
// 					                onSelect={onAdminSelect}
// 					                className={'col-3 me-3'}
// 						// init={adminFilter}
// 						              resetWhen={!adminFilter}
// 					/>}
//
// 					<RangeDatePicker
// 						fromPickerProps={{
// 							value: dateFromFilter,
// 							onChange: ([date]) => {
// 								setDateFromFilter(date)
// 								// setDateFromFilter(date && getIsoUtcDate(date));
// 							},
// 							options: {maxDate: dateToFilter},
// 						}}
// 						toPickerProps={{
// 							value: dateToFilter,
// 							onChange: ([date]) => {
// 								setDateToFilter(date)
// 								// setDateToFilter(
// 								//   date && getIsoUtcDate(dayjs(date).second(59).toDate())
// 								// );
// 							},
// 							options: {minDate: dateFromFilter},
// 						}}
// 						className={''}
// 					/>
// 				</div>}
// 			</div>
//
// 			<ul className={'my-2 list-unstyled'}>
// 				{pillsOptions.map(({urlKey, label}) => {
// 					const value = label
// 					if (!value) return null;
//
// 					const finalLabel = isFunction(label) ? label(value) : label || value;
//
// 					return (
// 						<li key={urlKey} className={'chip mb-0 me-2 bg-white'}>
// 							<div className={'chip-body'}>
// 								<span className="chip-text">{finalLabel}</span>
//
// 								<div
// 									className="chip-closeable"
// 									onClick={() => onClosePill(urlKey)}
// 								>
// 									<i className="bx bx-x"/>
// 								</div>
// 							</div>
// 						</li>
// 					);
// 				})}
// 			</ul>
// 		</>
// 	)
// }
//
// export default FiltersHistoryPage