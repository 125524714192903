import {useEffect, useState} from 'react'
import {ModalDialog} from '../../../components/modals/ModalDialog';
import {createNewSetup} from '../../../API/api';
import {MySelect} from '../../../components/form/MySelect/MySelect';
import {Input} from '../../../components/form/Input';
import {MySearchSelect} from '../../../components/form/MySelect/MySearchSelect';
import {closeMyModal} from '../../../components/MyModal/showModal';

export const CreateSetupModal = ({onCreate}) => {
	const [selectedDevice, setSelectedDevice] = useState(null)
  const [prevSelectedDevice, setPrevSelectedDevice] = useState(null)
	const [serialNumber, setSerialNumber] = useState(null)
	const [versionAndroid, setVersionAndroid] = useState(null)
	const [error, setError] = useState(null)
	
	useEffect(() => {
		if (selectedDevice !== prevSelectedDevice && prevSelectedDevice != null) setVersionAndroid({})
	}, [selectedDevice, prevSelectedDevice])

	const initSetup = () => {
		const data = {
			serial_number: serialNumber,
			phone_model: selectedDevice.value.modelID,
			version_android: versionAndroid.value
		}
		
		createNewSetup(data)
			.then(onCreate)
			.catch(({response}) => {
				if (response) {
					// let text = Object.values(response.data).toString()
          let text = response?.data?.errors?.[0]?.detail
					if (text.includes('уже существует'))
						text = 'Настройка с таким серийным номером уже существует'
					
					setError(text)
				}
			})
	}
	
	const onSerialNumberChange = event => {
		setError(null)
		setSerialNumber(event.target.value)
	}
	
	const {versionsOptions} = selectedDevice?.value || {};

	return (
		<ModalDialog
			head={'Создание новой настройки'}
			body={
				<>
					<div>
						<Input className={'w-100'}
						       label={'СЕРИЙНЫЙ НОМЕР УСТРОЙСТВА'}
						       onChange={onSerialNumberChange}
						       errorMsg={error}
						       placeholder={'Введите номер'}/>
					</div>
					
					<div className={'mt-2'}>
						<label className={'mb-50'}>МАРКА И МОДЕЛЬ УСТРОЙСТВА</label>
						
						<MySearchSelect
							type={'setupDevices'}
							initText={'-Выберите'}
							noSearchInput
							onSelect={(item) => {
                setPrevSelectedDevice(selectedDevice);
                setSelectedDevice(item)
              }}/>
					</div>
					
					<div className={'mt-2'}>
						<label className={'mb-50'}>ВЕРСИЯ ANDROID</label>
						
						<MySelect
							initText={'-Выберите'}
							keyText={'label'}
							// init={versionsOptions?.[0]?.value ? true : false}
              init={true}
							disabled={!versionsOptions}
							optionsArr={versionsOptions}
							resetWhen={!versionAndroid?.value}
							onSelect={(item) => setVersionAndroid(item)}/>
					</div>
				</>
			}
			showFooter
			footerProps={{
				successText: 'Продолжить',
				onSuccessClick: initSetup,
				onCancelClick: closeMyModal,
				successDisabled: !(selectedDevice && serialNumber && versionAndroid),
			}}
		>
		</ModalDialog>
	)
	
	
}