import {loggedAdmin} from '../../stores/loggedAdmin'
import {useMemo} from 'react'
import {observer} from 'mobx-react-lite'
import MenuAdminCard from './MenuAdminCard'
import {navMenuItems} from './menuItems'
import {isNotEmpty} from '../../js-helpers/helpers';
import {Head} from '../../components/UI/Head';


export const MenuAdmin = observer(() => {
	const {getIsResolved, data} = loggedAdmin
	
	const filteredMembersMenuItems = useMemo(() =>
		navMenuItems.filter(({permission}) => getIsResolved(permission)), [data])
	
	// const filteredManagementItemsMenu = useMemo(() =>
	// 	managementMenuItems.filter(({permission}) => getIsResolved(permission)), [data])
	//
	// const filteredTablesMenuItemsMenu = useMemo(() =>
	// 	tablesMenuItems.filter(({permission}) => getIsResolved(permission)), [data])
	// console.log({filteredMembersMenuItems})
	
	return (
		<>
			<Head title={'Администрирование'}/>
			{/*rock - isNotEmpty*/}
			{/*{filteredMembersMenuItems.length > 0 &&*/}
			{isNotEmpty(filteredMembersMenuItems) &&
			<MenuAdminCard menuItems={filteredMembersMenuItems}/>
			}
			{/*{isNotEmpty(filteredTablesMenuItemsMenu) &&*/}
			{/*<MenuAdminCard menuItems={filteredTablesMenuItemsMenu} title={'Таблицы данных'}/>*/}
			{/*}*/}
			
			{/*{isNotEmpty(filteredManagementItemsMenu) &&*/}
			{/*	<MenuAdminCard menuItems={filteredManagementItemsMenu} title={'Управление Системой'}/>*/}
			{/*}*/}
		</>
	)
})