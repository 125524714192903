import {makeAutoObservable, observable, runInAction} from 'mobx';
import {isEmpty, isEqualSome} from '../../js-helpers/helpers';
import {toast} from 'react-toastify';

export const exportDataStore = makeAutoObservable({
	isReady: true,
	savedData: observable.map(),
	
	requestParamsWithSavedIDS: observable.map(),
	get savedIDSFromParams() {
		return this.requestParamsWithSavedIDS.get(
			JSON.stringify(this.requestParams)
		)
	},
	
	includedIDS: [],
	get includedIDSCount() {
		return this.includedIDS.length
	},
	clearIncludedIDS() {
		this.includedIDS = []
	},
	
	requestMethod: null,
	set currRequestMethod(method) {
		this.requestMethod = method
	},
	
	requestParams: {},
	set currRequestParams(params) {
		this.requestParams = params
	},
	
	pageData: [],
	set currPageData(data) {
		this.pageData = data
	},
	
	prepareItemFunc: null,
	set currPrepareItemFunc(func) {
		this.prepareItemFunc = func
	},
	
	onCheckItem(id, checked) {
		const set = new Set(this.includedIDS)
		const savedItem = this.savedData.get(id)
		
		checked
			? set.add(id)
			: set.delete(id)
		
		this.includedIDS = [...set]
		
		!savedItem &&
		this.savedData.set(id, this.pageData.find(
			item => item.keycloak_id ? item.keycloak_id === id : item.id === id)
		)
	},
	
	getIsItemChecked(id) {
		return isEqualSome(id, this.includedIDS)
	},
	
	toggleAllChecked(isCheckedAll) {
		const savedIDS = this.savedIDSFromParams
		const currIncludedIDS = this.includedIDS
		
		if (savedIDS) {
			this.includedIDS =
				isCheckedAll
					? [...new Set([...currIncludedIDS, ...savedIDS])]
					: currIncludedIDS.filter(id =>
						!savedIDS.includes(id))
		} else {
			this.getData().then(items => {
				const newIDS = []
				const newSavedData = new Map()
				
				items?.forEach(item => {
					if (item.keycloak_id) {
						newSavedData.set(item.keycloak_id, item)
						newIDS.push(item.keycloak_id)
					} else {
						newSavedData.set(item.id, item)
						newIDS.push(item.id)
					}
				})
				
				runInAction(() => {
					this.savedData.merge(newSavedData)
					this.includedIDS =
						isCheckedAll
							? [...new Set([...currIncludedIDS, ...newIDS])]
							: currIncludedIDS.filter(id => !newIDS.includes(id))
					
					this.requestParamsWithSavedIDS.set(
						JSON.stringify(this.requestParams),
						newIDS)
				})
			})
		}
	},
	
	onCheckAllOnPage({target: {checked}}) {
		const newIDS = this.pageData.map(item => item.keycloak_id ? item.keycloak_id : item.id)
		const currIncludedIDS = this.includedIDS
		
		this.includedIDS =
			checked
				? [...new Set([...currIncludedIDS, ...newIDS])]
				: currIncludedIDS.filter(id => !isEqualSome(id, newIDS))
		
		if (checked) {
			this.pageData.forEach(item => {
				if (item.keycloak_id) {
					this.savedData.set(item.keycloak_id, item)
				} else {
					this.savedData.set(item.id, item)
				}
			})
		}
	},
	
	getIsAllCheckedOnPage() {
		if (this.includedIDSCount === 0)
			return false
		
		const currIncludedIDS = this.includedIDS
		return this.pageData.every(item => item.keycloak_id ? isEqualSome(item.keycloak_id, currIncludedIDS) : isEqualSome(item.id, currIncludedIDS))
	},
	async getData() {
		this.isReady = false
		
		const exportList = []
		let oneRequestPageCount = 100
		let _page = 1
		let progress = 0
		let toastText = ''
		
		const getProgressText = (progress) =>
			`Обновление данных экспорта: ${Math.ceil(progress * 100)}%`
		
		const toastId = toast.success(
			getProgressText(progress),
			{
				autoClose: false,
				closeOnClick: false,
				position: 'top-center',
				hideProgressBar: false,
				isLoading: true,
				progress
			}
		)
		
		const requestData = async (page, toastId) => {
			if (this.isReady) {
				throw new Error(':(')
			}
			
			const {data} = await this.requestMethod({
				...this.requestParams, page, page_size: oneRequestPageCount
			})
			
			oneRequestPageCount = data.results.length
			progress = page / Math.ceil(data.count / oneRequestPageCount)
			toast.update(toastId, {
				render: getProgressText(progress),
				progress,
			})
			
			const items = this.prepareItemFunc
				? data.results.map(this.prepareItemFunc)
				: data.results
			
			exportList.push(...items)
			if (data.next)
				await requestData(++page, toastId)
		}
		
		try {
			await requestData(_page, toastId)
			
			if (isEmpty(exportList)) {
				toastText = 'Нет данных для экспорта'
				return
			}
			
			toastText = 'Экспорт обновлен'
			
			runInAction(() => {
				toast.update(toastId, {
					render: toastText,
					autoClose: true,
					isLoading: false,
					closeOnClick: true,
					progress: null,
				})
				// this.isReady = true
			})
			
			return exportList
		} catch (e) {
			toast.update(toastId, {
				type: 'error',
				render: `Не удалось обновить экспорт: ${e.message}`,
				isLoading: false,
				closeOnClick: true,
			})
		} finally {
			// runInAction(() => {
			// 	toast.update(toastId, {
			// 		render: toastText,
			// 		autoClose: true,
			// 		isLoading: false,
			// 		closeOnClick: true,
			// 		progress: null,
			// 	})
			this.isReady = true
			// })
		}
	},
	get exportData() {
		return this.includedIDS.map(id => this.savedData.get(id))
	},
	
	onSaveBtnClick() {},
	set onSaveExport(cb) {
		this.onSaveBtnClick = () => cb(this.exportData)
	},
	
	resetStore() {
		this.isReady = true
		this.savedData = observable.map()
		this.includedIDS = []
		this.requestMethod = null
		this.requestParamsWithSavedIDS = observable.map()
		this.requestParams = {}
		this.pageData = []
		this.prepareItemFunc = null
	}
}, {savedData: false}, {autoBind: true})