import {useState} from 'react';
import {useUpdateEffect} from '@react-hookz/web';
import {isNotEmpty} from '../../../../../js-helpers/helpers';

//useChangeStepsResolver
export default ({currSectionData, isResolveChangeStage, unsavedSetups}) => {
	const [allPossibleToChangeIDS, setAllPossibleToChangeIDS] = useState([])
	const [resolvedChangeIDs, setResolvedChangeIDs] = useState([])
	
	useUpdateEffect(() => {
		const withoutParentsIDS = []
		
		currSectionData?.setting.forEach(setting => {
			withoutParentsIDS.push(
				...isNotEmpty(setting.subitem)
					? setting.subitem.map(({id}) => id)
					: [setting.id]
			)
		})
		
		setAllPossibleToChangeIDS(withoutParentsIDS)
		
		if (isResolveChangeStage) {
			const lastCheckedID =
				isNotEmpty(unsavedSetups) &&
				Number(Object.keys(unsavedSetups).at(-1))

        
			
			changeResolvedIDS(Boolean(lastCheckedID), lastCheckedID || withoutParentsIDS.at(0), withoutParentsIDS)
		} else {
			setResolvedChangeIDs([])
		}
	}, [currSectionData])
	
	const getIsResolvedChange = (stepID) => resolvedChangeIDs.some(_id => _id === stepID)
	
	const changeResolvedIDS = (checked, stepID, withoutParentsIDS = allPossibleToChangeIDS) => {
		const stepIndexInAllPossible = withoutParentsIDS.findIndex(_id => _id === stepID)
		let startIndex = stepIndexInAllPossible
		let endIndex = stepIndexInAllPossible + 1
		
		checked ? ++endIndex : startIndex > 0 && (--startIndex)
		setResolvedChangeIDs(withoutParentsIDS.slice(startIndex, endIndex))
	}
	
	return {
		getIsResolvedChange,
		changeResolvedIDS
	}
}